
@import "../../style/constant.scss";
@import "../../style/mixins.scss";

.content-block{
    margin-bottom: 1.69492%;
    width: 100%;
    padding: 0 3.38983% 3.38983% 3.38983%;
    h1{
        font-size: 46px;
        font-weight: 300;
        border-bottom: solid 1px #666;
        margin: 0 0 25px 0;
        padding-bottom: 10px;
		@include allMobiles{
			font-size: 24px;
			margin-bottom: 10px;
			border: 0;
		}
		@include tablet{
			font-size: 30px;
			margin-bottom: 10px;
			border: 0;
		}
    }
    
}

.playlist-table {
	width: 100%;
	margin: 0;
	padding: 0;

	a {
		color: white;
		text-decoration: none;
	}

	thead {
		th {
			font-weight: $bold;
			color: $gray-117;
			text-transform: uppercase;
			padding: 20px 10px;
			text-align: left;
			font-size: 18px;
		}
	}

	tbody {
		tr {
			&:hover {
				background-color: rgba(102, 102, 102, 0.3);
			}

			&:last-child {
				border-bottom: 0;
			}
		}

		td {
			padding: 10px 10px;
		}
	}

	&.click-row {
		tbody tr:hover {
			cursor: pointer;
		}
	}

	tr {
		width: 100%;
		border-bottom: solid 1px $gray-68;
	}
	// Styling for public playlists
	&.public-playlists {
		thead, tbody {
			// Hide all columns as default
			th, td {
				display: none;
			}

			.playlist-title {
				display: table-cell;
				width: span(10);
			}

			.playlist-created-by {
				display: table-cell;
				width: span(2);
				text-align: right;
			}
		}
	}
	// Styling for "My playlists"
	&.user-playlists {

		thead, tbody {
			// Hide all columns as default
			th, td {
				display: none;
			}; 

			.playlist-title {
				display: table-cell;
				width: span(8);
			}

			.playlist-buttons {
				display: table-cell;
				width: span(4);
				text-align: right;

				.button {
					height: 35px;
					width: 35px;
					margin-right: 10px;

					&:last-child {
						margin-right: 0px;
					}
				}
			}
		}
	}
	// Styling for a single playlist containing items
	&.user-playlist-single {
		
		thead {
			.playlist-title {
				span { display:none; }
			}
		}

		thead, tbody {
			// Hide all columns as default
			th, td {
				display: none;
			}

			.playlist-image {
				display: table-cell;
				width: span(2);

				.sub-title, .episode-name {
					font-size: 14px;
				}
			}

			.playlist-title {
				display: table-cell;
				width: span(8);
				vertical-align:top;

				img { float: left; margin-right: 20px; max-height: 70px; }

				.playlist-title-content {
					.head-title{
						font-weight: 700;
						font-size: 18px;
					}
					.sub-title, .episode-name {
						font-size: 14px;
					}
				}
			}

			/*.playlist-runtime {
				display: table-cell;
				width: span(2);
				//text-align: right;
			}*/

			.playlist-buttons {
				display: table-cell;
				width: span(2);
				text-align: right;

				.button {
					height: 35px;
					width: 35px;
					margin-right: 10px;

					&:last-child {
						margin-right: 0px;
					}

					&.favourite-button {
						float:none;
						position:inherit;
					}
				}
			}
		}

		tfoot {
			td {
				padding: 10px 10px;
			}
		}
	}
}

.popover-container {

	&.popover-playlist-items {

		ul {
			list-style-type: none;
			margin: 10px;
			padding: 0;
			height: 160px;
			overflow-y: auto;

			li {
				font-size: 18px;
				font-weight: $bold;
				text-decoration: none;
				text-align: left;
				color: white;
				line-height: 20px;
				display: block;
				border-radius: 3px;
				min-height: 40px;
				margin: 0;
				cursor: pointer;
				padding: 10px 15px 10px 35px;
				background-color: transparent;
				background-image: url('../../images/plus-white.svg');
				background-repeat: no-repeat;
				background-size: 12px 12px;
				background-position: 10px 14px;

				&:hover {
					background-color: $gray-32;
				}

				&.added {
					background-image: url('../../images/checkmark.svg');
				}
			}
		}
	}
}

.featured-playlists {

	@include clearfix;
	margin: 40px 0 20px 0;

	.playlist {
		margin-bottom: 25px;
		padding-bottom: 15px;
		//border-bottom: solid 1px $gray-68;
		border-bottom: solid 1px rgba(102, 102, 102, 1.0);

		&:last-child {
			border-bottom: none;
		}

		h3 {
			color: white;
			font-size: 18px;
			margin-top: 5px;
			margin-bottom: 5px;
		}

		> a {
			width: 100%;
			height: 100%;
			display: block;
			text-decoration: none;
		}

		.details {
			color: $gray-117;
			font-size: 14px;

			.created-by {
				color: white;
			}
		}
	}
}

.featured-playlists, .playlist-table {

	.image-single {
		img {
			max-width: 100%;
		}
	}

	.image-multiple {
		@include clearfix;

		img {
			float: left;
			width: 50%;
		}
	}
}

.add-playlist-button-container {
	margin: 40px 20px;

	a {
		color: white;
		text-decoration: none;
	}
}

.featured-playlists-block {
	@include clearfix;
	position: relative;
	padding-bottom: 30px;
}

/******************************************************
 ____                  _ _
/ ___| _ __ ___   __ _| | |
\___ \| '_ ` _ \ / _` | | |
 ___) | | | | | | (_| | | |
|____/|_| |_| |_|\__,_|_|_|

******************************************************/
@include respond-below(sm) {
	.featured-playlists {
		.playlist {
			// @include span(6);
			border-bottom: none;

			&:nth-of-type(2n) {
				// @include last;
			}

			h3 {
				margin-bottom: 10px;
			}
		}
	}
}

/******************************************************
 __  __          _ _
|  \/  | ___  __| (_)_   _ _ __ ___
| |\/| |/ _ \/ _` | | | | | '_ ` _ \
| |  | |  __/ (_| | | |_| | | | | | |
|_|  |_|\___|\__,_|_|\__,_|_| |_| |_|

******************************************************/

@include smallDesktop{
    .content-block {
        padding: 0 1.69492% 1.69492% 1.69492%;
    }
	.playlist-table {
		thead {
			th {
				padding: 20px 20px;
			}
		}

		tbody {
			td {
				padding: 10px 20px;
				color: $gray-139;
			}
		}
		// Styling for public playlists
		&.public-playlists {
			thead, tbody {

				.playlist-image {
					display: table-cell;
					width: span(2);

					.playlist-images {
						width: 120px;
					}
				}

				.playlist-title {
					display: table-cell;
					width: span(5);
					font-weight: bold;

					span {
						display: none;
					}
				}

				.playlist-created-by {
					display: table-cell;
					width: span(2);
					text-align: left;
				}

				.playlist-created-date {
					display: table-cell;
					width: span(3);
					text-align: right;
				}
			}
		}
		// Styling for user playlists ("My playlists")
		&.user-playlists {

			thead, tbody {

				.playlist-image {
					display: table-cell;
					width: span(2);

					.playlist-images {
						width: 120px;
					}
				}

				.playlist-title {
					display: table-cell;
					width: span(5);

					span {
						display: none;
					}
				}

				.playlist-created-date {
					display: table-cell;
					width: span(2);
				}

				.playlist-buttons {
					display: table-cell;
					width: span(2);
					text-align: right;
				}
			}
		}
		// Styling for a single playlist containing items
		&.user-playlist-single {

			thead {
				.playlist-title {
					span {
						display: none;
					}
				}
			}

			thead, tbody {
				// Hide all columns as default
				th, td {
					display: none;
				}

				.playlist-image {
					display: table-cell;
					width: span(3);

					.playlist-images {
						width: 120px;
					}
				}

				.playlist-title {
					display: table-cell;
					width: span(5);
				}

				.playlist-runtime {
					display: table-cell;
					width: span(2);
					text-align: right;
				}

				.playlist-source {
					display: table-cell;
					width: span(2);
					text-align: right;
				}
			}
		}
	}



	.featured-playlists {
		margin: 60px 0 40px 0;
		//margin-bottom: 40px;
		.playlist {

			// @include span(3);
			border-bottom: none;

			&:nth-of-type(4n) {
				// @include last;
			}

			h3 {
				height: 18px;
				line-height: 18px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				margin-bottom: 10px;
				margin-top: 10px;
			}
		}
	}
}

/******************************************************
 _
| |    __ _ _ __ __ _  ___
| |   / _` | '__/ _` |/ _ \
| |__| (_| | | | (_| |  __/
|_____\__,_|_|  \__, |\___|
                |___/

******************************************************/
  @include smallDesktop {
	.playlist-table {
		&.public-playlists {
			.playlist-count {
				display: table-cell;
				width: span(2);
			}
		}
		// Styling for user playlists ("My playlists")
		&.user-playlist-single {
           
			thead, tbody {

				.playlist-image {
					display: table-cell;
					width: span(2);
				}

				.playlist-title {
					display: table-cell;
                    width: 40.67797%;
				}

				.playlist-created-date {
					display: table-cell;
					width: span(2);
				}

				.playlist-count {
					display: table-cell;
					width: span(2);
				}

				.playlist-buttons {
					display: table-cell;
					width: span(2);
					text-align: right;
				}
			}
		}
	}
}
