@import './constant.scss';
// @charset "UTF-8";

/// A mixin for generating vendor prefixes on non-standardized properties.
///
/// @param {String} $property
///   Property to prefix
///
/// @param {*} $value
///   Value to use
///
/// @param {List} $prefixes
///   Prefixes to define
///
/// @example scss - Usage
///   .element {
///     @include prefixer(border-radius, 10px, webkit ms spec);
///   }
///
/// @example css - CSS Output
///   .element {
///     -webkit-border-radius: 10px;
///     -moz-border-radius: 10px;
///     border-radius: 10px;
///   }
///
/// @require {variable} $prefix-for-webkit
/// @require {variable} $prefix-for-mozilla
/// @require {variable} $prefix-for-microsoft
/// @require {variable} $prefix-for-opera
/// @require {variable} $prefix-for-spec


@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      @if $prefix-for-webkit {
        -webkit-#{$property}: $value;
      }
    } @else if $prefix == moz {
      @if $prefix-for-mozilla {
        -moz-#{$property}: $value;
      }
    } @else if $prefix == ms {
      @if $prefix-for-microsoft {
        -ms-#{$property}: $value;
      }
    } @else if $prefix == o {
      @if $prefix-for-opera {
        -o-#{$property}: $value;
      }
    } @else if $prefix == spec {
      @if $prefix-for-spec {
        #{$property}: $value;
      }
    } @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin disable-prefix-for-all() {
  $prefix-for-webkit:    false !global;
  $prefix-for-mozilla:   false !global;
  $prefix-for-microsoft: false !global;
  $prefix-for-opera:     false !global;
  $prefix-for-spec:      false !global;
}

@mixin transform($property: none) {
  // none | <transform-function>
  @include prefixer(transform, $property, webkit moz ms o spec);
}

@mixin transform-origin($axes: 50%) {
  // x-axis - left | center | right  | length | %
  // y-axis - top  | center | bottom | length | %
  // z-axis -                          length
  @include prefixer(transform-origin, $axes, webkit moz ms o spec);
}

@mixin transform-style($style: flat) {
  @include prefixer(transform-style, $style, webkit moz ms o spec);
}




/// Provides an easy way to include a clearfix for containing floats.
///
/// @link http://cssmojo.com/latest_new_clearfix_so_far/
///
/// @example scss - Usage
///   .element {
///     @include clearfix;
///   }
///
/// @example css - CSS Output
///   .element::after {
///     clear: both;
///     content: "";
///     display: table;
///   }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin columns($arg: auto) {
  // <column-count> || <column-width>
  @include prefixer(columns, $arg, webkit moz spec);
}
@mixin appearance($value) {
  @include prefixer(appearance, $value, webkit moz ms o spec);
}


@mixin transition($properties...) {
  // Fix for vendor-prefix transform property
  $needs-prefixes: false;
  $webkit: ();
  $moz: ();
  $spec: ();

  // Create lists for vendor-prefixed transform
  @each $list in $properties {
    @if nth($list, 1) == "transform" {
      $needs-prefixes: true;
      $list1: -webkit-transform;
      $list2: -moz-transform;
      $list3: ();

      @each $var in $list {
        $list3: join($list3, $var);

        @if $var != "transform" {
          $list1: join($list1, $var);
          $list2: join($list2, $var);
        }
      }

      $webkit: append($webkit, $list1);
      $moz:    append($moz,    $list2);
      $spec:   append($spec,   $list3);
    } @else {
      $webkit: append($webkit, $list, comma);
      $moz:    append($moz,    $list, comma);
      $spec:   append($spec,   $list, comma);
    }
  }

  @if $needs-prefixes {
    -webkit-transition: $webkit;
       -moz-transition: $moz;
            transition: $spec;
  } @else {
    @if length($properties) >= 1 {
      @include prefixer(transition, $properties, webkit moz spec);
    } @else {
      $properties: all 0.15s ease-out 0s;
      @include prefixer(transition, $properties, webkit moz spec);
    }
  }
}

@mixin transition-property($properties...) {
  -webkit-transition-property: transition-property-names($properties, "webkit");
     -moz-transition-property: transition-property-names($properties, "moz");
          transition-property: transition-property-names($properties, false);
}

@mixin transition-duration($times...) {
  @include prefixer(transition-duration, $times, webkit moz spec);
}

@mixin transition-timing-function($motions...) {
  // ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier()
  @include prefixer(transition-timing-function, $motions, webkit moz spec);
}

@mixin transition-delay($times...) {
  @include prefixer(transition-delay, $times, webkit moz spec);
}
@mixin user-select($value: none) {
  @include prefixer(user-select, $value, webkit moz ms spec);
} 

@mixin hoverAnimation {
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 0;
        height: 2px;
        background: linear-gradient(to right,#388603,#010f50,#3717aa,#be2fe2);
        background-size: 300% 100%;
        transition: width .3s ease .3s,left .3s ease .3s,
        background-position .3s ease 0s,box-shadow .3s ease;
    }
    &:hover:after{
        left: 5%;
        width: 90%;
        background-position: 100% 0;
        box-shadow: 0 4px 15px 0 rgba(255,30,41,.3);
        transition: width .3s ease 0s,left .3s ease 0s,
        background-position .3s ease .3s;
    }
}
$breakpoint: map-get($breakpoints, sm);

@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Respond above.
@mixin respond-above($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
  
      // Get the breakpoint value.
      $breakpoint-value: map-get($breakpoints, $breakpoint);
  
      // Write the media query.
      @media (min-width: $breakpoint-value) {
        @content;
      }
  
    // If the breakpoint doesn't exist in the map.
    } @else {
  
      // Log a warning.
      @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
  }
  

  @mixin grey-gradient{
    background: rgb(125,126,125); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(125,126,125,1) 0%, rgba(76,76,76,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#4c4c4c',GradientType=0 ); /* IE6-9 */
}



$tablet-width: 768px;
$smalldesktop-width: 1090px;
$largedesktop-width: 1230px;
$smallargedesktop-width:1320px;
$bigdesktop-width: 1650px;
$mobile-width: 500px;
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}
@mixin allMobiles {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}


@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}
@mixin smallDesktop {
  @media (min-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}
@mixin largeDesktop {
  @media (min-width: #{$largedesktop-width - 1px}) {
    @content;
  }
}
@mixin smallDesktopAndMobile {
  @media (max-width: #{$smalldesktop-width - 1px}) {
    @content;
  }
}
@mixin smallDesktopAndLargeDestop {
  @media (max-width: #{$smallargedesktop-width - 1px}) {
    @content;
  }
}
@mixin bigDesktop {
  @media (min-width: #{$bigdesktop-width - 1px}) {
    @content;
  }
}