@import '../style/constant.scss';
@import '../style/mixins.scss';
.main-content{
    overflow-x: hidden;
    position: relative;
	width: 100%;
	overflow: auto;
	z-index: 1;
	background: black;
	left: 0px;
	-webkit-overflow-scrolling: touch;
	@include transition(left 0.35s ease);
    .container{
        height: 100%;
        padding:0 15px;
        position: relative;
        margin-bottom: 1rem;
        @include allMobiles{
            padding: 0;
        }
    }
}
.sticky-ads{
    display: none;
}

ul,h2{
    margin-bottom: 2rem;
}

.info-bar{
    background:#151515;
    width: 100%;
    .category-drop-down{
        margin-top: 4px;
    }

    top:0;
        p{
        margin: 0;
        line-height: 2.2rem;
        font-size: 0.8rem;
        span{
            margin-right: 10px;
            padding-right: 10px
        }
    }
}


@include respond-above(md) {
    .info-bar{
        .container{
            padding: 0 15px;
        }
    }
}
@include smallDesktopAndMobile{
    iframe{
        max-width: 100%;
        img{
            max-width: 100%;
        }
    }
    .top-add-container{
        max-width: 100%;
        min-height: 320px;
    }
   
}
@include largeDesktop {
    .outer-container{
        margin: 0 auto;
        background:#000;
        position: relative;
        max-width: 100%;
        padding: 0;
    }
    .sticky-ads{
        display: block;
        // width: 200px;
        position: absolute;
        left: 1000px;
        .secondAds{
            position: fixed;
            top:0;
        }
    }
    .side-ads{
        position: absolute;
        left: 1000px;
        >div{
            position: static;
            top:0;
        }
    }
  }

@include mobile{
    .sticky-ads,
    .side-ads{
        display: none;
    }
}


@media all and (max-width: 1229px) {
    .sticky-ads,
    .side-ads{
        display: none;
    }
}