@import "../style/constant.scss";
@import "../style/mixins.scss";

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  color: #fff;
  font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1.63;
  margin: 0 0 0 0;
  max-width: 100%;
  min-width: 300px;
  padding: 0 0 0 0;
  position: relative;
  text-rendering: optimizeLegibility;
  width: 100%;
}

* {
  // font-family: 'Roboto', sans-serif;
  font-family: "proxima-nova", sans-serif;
}

a {
  color: #25e6ed;
  text-decoration: none;
}

.dflex {
  display: flex;
  justify-content: flex-end;
}

.my-list {
  margin: 0 0 2rem 0;
  background: rgb(0, 116, 170); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0074aa', endColorstr='#615c82', GradientType=0); /* IE6-9 */
  border-radius: 10px;
  min-height: 300px;
  h2 {
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    .print-icon {
      a {
        color: white;
      }
    }
  }
  .text {
    color: #fff;
    padding: 0.5rem;
    display: block;
    font-size: 11px;
  }
  ul {
    padding: 0 5px 15px 5px;
    margin: 0;
    li {
      list-style: none;
      color: #fff;
      font-size: 11px;
      margin-bottom: 10px;
      cursor: pointer;
      .smalllogo {
        float: left;
        margin-right: 5px;
        width: 30px;
        img {
          max-width: 100%;
        }
      }
      .remove-icon {
        float: right;
        display: inline-block;
        background: #fff;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        cursor: pointer;
        svg {
          float: right;
          color: #c32626;
          font-size: 14px;
          margin-top: 1px;
          margin-right: 1px;
        }
      }
      .name {
        float: left;
        width: calc(100% - 55px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.forgot-password {
  .footer {
    margin-top: 25%;
  }
  .register-form {
    .btn-primary {
      max-width: 310px;
    }
  }
}

.element-input,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"] {
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #000;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 40px;
  line-height: 1;
  margin: 0 0 0 0;
  overflow: hidden;
  padding: 12px;
  text-overflow: ellipsis;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  white-space: nowrap;
  width: 100%;
}

form input[type="text"],
form input[type="email"],
form input[type="password"] {
  color: #202020;
  border-radius: 5px;
  border: 0;
  padding: 8px;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 25px;
}

.button {
  font-weight: $regular;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 99999px;
  display: inline-block;

  &.with-text {
    text-transform: none !important;
    width: auto !important;
    height: auto !important;
    font-weight: $bold;
    font-size: 18px;
    background-position: left center;
    background-size: 35px;
    padding: 7px 0 7px 45px;
  }
}

// Color combinations
.cyan-background {
  color: black;
  background-color: $cyan;
  border: solid 2px transparent;

  &:hover {
    text-decoration: none;
    color: black;
  }

  &.disabled {
    background-color: $cyan-disabled;
  }
}

.cyan-outline {
  color: $cyan;
  background-color: transparent;
  border: solid 2px $cyan;
}

.cyan-link {
  color: $cyan;
  font-size: 14px;
  border: 0;
  background-color: transparent;
}

.white-background {
  color: black;
  background-color: white;
  border: 0;
}

// Sizes
.button-large {
  font-size: 14px;
  padding: 10px 25px;
  line-height: 22px;
}

.button-medium {
  font-size: 14px;
  padding: 10px 25px;
  line-height: 22px;
}

.button-small {
  font-size: 14px;
  padding: 10px 25px;
  line-height: 11px;
}

.button-thin {
  font-size: 12px;
  font-weight: $semibold;
  padding: 0 10px;
  border-radius: 18px;
  line-height: 26px;
  min-width: 120px;
}

.button-short {
  max-width: 310px;
  float: right;
}

// Add button
.add-button,
.favourite-button,
.trailer-button {
  outline: none;
  border: 0;
}

.large-card-add-button,
.large-card-favourite-button {
  position: absolute;
  height: 35px;
  width: 35px;
  bottom: 10px;
}

.large-card-add-button {
  right: 10px;
  display: none;
}

.large-card-favourite-button {
  right: 10px;
  //right: (35px + 10px * 2 - 5px);
}

.large-card-trailer-button {
  right: 10px;
}

.large-card-favourite-button ~ .large-card-trailer-button {
  right: (35px + 10px * 2 - 5px);
}

.small-card-add-button,
.small-card-favourite-button,
.small-card-trailer-button {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 10px;
}

a.small-card-trailer-button {
  background-size: cover;
}

.small-card-add-button {
  right: 10px;
  display: none;
}

.small-card-favourite-button {
  right: 10px;
  /*right: (30px + 10px * 2);*/
}

.small-card-trailer-button {
  right: (30px + 10px * 2);
}

.large-add-button,
.large-trailer-button,
.large-favourite-button {
  position: relative;
  /*height: 47px;
  width: 47px;*/
  height: 30px;
  width: 30px;
  float: left;
}

// Reminder button
.remind-button {
  outline: none;
  border: 0;

  background: transparent url("../images/reminder_unchecked.svg") no-repeat
    center center;

  &:hover,
  &.active {
    background: transparent url("../images/reminder_checked.svg") no-repeat
      center center;
  }
}

.small-card-remind-button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
}

.follow-button {
  position: relative;
  height: 47px;
  width: 180px;
  float: left;
  margin-right: 20px;
}

.add-white {
  background: transparent url("../images/add-to-list-icon2.svg") no-repeat
    center center;
}

.add-cyan {
  background: transparent url("../images/add-cyan.svg") no-repeat center center;
}

.favourite-white,
.watched {
  background: transparent url("../images/add-added-white-2-35px.svg") no-repeat
    center center !important;
}

.favourite-cyan {
  background: transparent url("../images/favourite-cyan.svg") no-repeat center
    center;
}

.unfavourite-white {
  background: transparent url("../images/add-white.svg") no-repeat center center;
}

.unfavourite-cyan {
  background: transparent url("../images/unfavourite-cyan.svg") no-repeat center
    center;
}

.remove-white {
  background: transparent url("../images/remove-white.svg") no-repeat center
    center;
}

.more-white {
  background: transparent url("../images/more-white.svg") no-repeat center
    center;
}

.edit-white {
  background: transparent url("../images/edit-white.svg") no-repeat center
    center;
}

.trailer-white {
  background: transparent url("../images/trailer-play-icon.svg") no-repeat
    center center;
}

// Create account and login links
.account-buttons-wrapper {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

#create-account-button {
  width: 100%;
}

.page-login {
  margin-top: 20px;
}

// Facebook and Google buttons

.social-media-button {
  font-size: 18px;
  color: white;
  text-align: center;
  display: block;
  background-position: left 20px center;
  background-size: auto 20px;
  background-repeat: no-repeat;
  border: solid 2px transparent;
  margin-bottom: 20px;
  margin-top: 0;
  width: 100%;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.facebook-button {
  background-color: $facebook-blue;
  background-image: url("../images/facebook-icon.svg");
  &:hover {
    background-color: tint($facebook-blue, 10%);
  }
}

.google-button {
  background-color: $google-red;
  background-image: url("../images/google-icon.svg");
  &:hover {
    background-color: tint($google-red, 10%);
  }
}

.twitter-button {
  background-color: $twitter-blue;
  background-image: url("../images/twitter-icon.svg");
  &:hover {
    background-color: tint($twitter-blue, 10%);
  }
}

/*.email-button {
	background-color: $email-green;
    padding-left: 12px !important;
	&:hover { background-color: tint($email-green, 10%); }
}*/

.share-button {
  font-size: 14px;
  font-weight: $semibold;
  line-height: 21px;
  color: white;
  text-align: center;
  background-position: left 12px center;
  background-size: auto 16px;
  background-repeat: no-repeat;
  border: solid 2px transparent;
  margin-bottom: 20px;
  margin-top: 0;
  padding: 2px 12px 2px 35px;
  text-transform: none;

  &:hover {
    text-decoration: none;
    color: white;
  }

  &.facebook-button,
  &.twitter-button {
    background-position: left 8px center;
    padding: 2px 12px;
    float: left;
    height: 29px;
  }
  &.twitter-button {
    background-position: left 4px center;
  }
}

.email-button {
  @include appearance(none);
  background-color: transparent;
  border: solid 2px $cyan;
  border-radius: 9999px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: normal;
  color: $cyan;
  &:hover {
    background-color: $cyan;
    color: black;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1021;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  .modal-inner-container {
    background: rgba(256, 256, 256, 1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    position: relative;
    height: 100%;
    overflow-y: auto;
    max-height: 80%;
    width: 500px;
    margin: 0 auto;
    margin-top: 8vh;
    overflow-x: hidden;
  }
  .modal-footer.modal-footer {
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.5);
    padding: 1rem 20px;
    margin-top: 10px;
    border-radius: 0;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    width: 100%;
    background: #fff;
  }
  .close-modal {
    position: absolute;
  }
}

.switch-layout {
  font-size: 0.8rem;
  float: right;
  display: flex;
  align-items: center;
  margin: 5px 0;
  span {
    cursor: pointer;
  }
  .toggle-btn {
    min-width: 30px;
    min-height: 12px;
  }
}

.add-container {
  #cncpt-lb1 {
    max-width: 100%;
  }
  iframe {
    max-width: 100%;
  }
}

.filter {
  label {
    margin-right: 10px;
  }
  select {
    border: 1px #dcdfe6 solid;
    background-color: #4c9fc1;
    margin: 2px 0 0 0;
    width: 150px;
  }
}

.search-result {
  margin-top: -40px;
  .action-bar {
    margin-bottom: 20px;
    .heading {
      line-height: 23px;
      background: url("../images/new/menu_bg_unselected.gif");
      font-weight: bold;
      font-size: 11px;
      border-radius: 4px 4px 0 0;
      display: block;
      color: #fff;
      padding: 0 20px;
    }

    .filters {
      border: 1px solid #c6c6c6;
      border-radius: 0 0 4px 4px;
      padding: 5px 20px;
      .label {
        display: inline-block;
        margin-right: 20px;
      }
      select {
        height: 25px;
        margin-right: 20px;
      }
    }
  }
  .search-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      border-top: solid 1px #474747;
      height: 40px;
      line-height: 40px;

      > span {
        display: block;
        width: 25%;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .anchor-link {
        width: calc(50% - 130px);
      }
      .logo {
        text-align: right;
        width: 130px;
        background: none;
        height: 40px;
        // padding-top: 3px;
        margin: 0;
        img {
          max-width: 60px;
          max-height: 35px;
          vertical-align: middle;
        }
        span {
          float: right;
        }
      }
    }
  }
}

.anchor-link {
  color: #00a0dc;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.myProgramList-modal.myProgramList-modal.myProgramList-modal {
  min-height: 300px;
  height: 400px;
  overflow: hidden;
  .close {
    background: transparent;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: absolute;
    right: 10px;
    top: 5px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0;
    opacity: 1;
    font-size: 15px;
  }
  .my-list {
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px;
    .print-icon {
      margin-right: 20px;
    }
  }
}

.show-mylist {
  display: none;
  .showlist {
    border: 0;
    background: #f5a600;
    margin: 5px 0;
    border-radius: 4px;
    padding: 3px 5px;
  }
}

.channel-list-info {
  margin: 0 auto;
  margin-top: 40px;
  background: #2e2e2e;
  color: #fff;
  max-width: 780px;
  padding: 10px;
  border-radius: 10px;
  p {
    color: #fff;
    float: left;
    width: calc(100% - 60px);
    margin-bottom: 0;
  }
  .icon {
    background-image: url("../images/new/info_bg.gif");
    height: 40px;
    width: 40px;
    float: left;
    background-position: -13px -8px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

.play-icon {
  background: url("../images/trailer-play-icon.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.addTOlist-icon {
  background: url("../images/ic_addToList.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.addTOlist-icon2 {
  background: url("../images/ic_add-white.svg") 0 0 no-repeat;
  height: 25px;
  width: 25px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.like-icon {
  background: url("../images/ic_thumb-up-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.dislike-icon {
  background: url("../images/ic_thumb-down-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.heart-icon {
  background: url("../images/ic_heart.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  fill: #fff;
}

.welcome-message {
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.2);
  border-radius: 8px;
  padding: 20px 20px 5px 40px;
  margin-bottom: 20px;
  position: relative;
  .text {
    float: left;
    width: calc(100% - 120px);
    .close-btn {
      position: absolute;
      right: 13px;
      color: #222;
      font-weight: 500;
      font-size: 20px;
      padding: 3px;
      cursor: pointer;
      top: 3px;
    }
  }
  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  i {
    display: block;
    float: left;
    margin-right: 40px;
    margin-top: 20px;
  }
}

.disneyplus_icon {
  background: url("../images/provider/disneyplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.NETFLIX_icon,
.netflix_icon {
  background: url("../images/provider/netflix.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.netflix_full {
  background: url("../images/netflix-full.svg") 0 0 no-repeat;
  height: 40px;
  width: 80px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.dplay_full {
  background: url("../images/dplay-full.png") 0 0 no-repeat;
  height: 30px;
  width: 110px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 35px;
    background: url("../images/provider/discoveryplus.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}

.amazonprimevideo_full {
  background: url("../images/prime-video-full.svg") 0 -5px no-repeat;
  height: 100px;
  width: 140px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 35px;
    background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
    background-size: 100% 100%;
  }
}
.svtplay_full {
  background: url("../images/svt-play-full.svg") 0 0 no-repeat;
  height: 70px;
  width: 100px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 35px;
  }
}

.tv4play_full {
  background: url("../images/tv4-play-full.svg") 0 0 no-repeat;
  height: 70px;
  width: 100px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 35px;
  }
}

.hbomax_full {
  background: url("../images/hbomax-full.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 45px;
    width: 45px;
  }
}
.viaplay_full {
  background: url("../images/viaplay-full.svg") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 40px;
  }
}
.appletvplus_full {
  background: url("../images/apple-tv-plus-full.svg") 0 0 no-repeat;
  height: 60px;
  width: 90px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 45px;
  }
}

.skyshowtime_full {
  background: url("../images/SkyShowtime-full.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 40px;
    width: 40px !important;
  }
}
.disneyplus_full {
  background: url("../images/disney_plus_icon-full.png") 0 0 no-repeat;
  height: 70px;
  width: 90px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
  @include allMobiles {
    height: 35px;
  }
}

.PRIME_VIDEO_icon,
.primevideo_icon,
.amazonprimevideo_icon {
  background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.VIA_PLAY_icon,
.viaplay_icon {
  background: url("../images/provider/vaiplay.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.viaplay_icon,
.dplay_icon,
.disneyplus_icon,
.primevideo_icon,
.amazonprimevideo_icon,
.svtplay_icon,
.sfanytime_icon,
.viafree_icon,
.netflix_icon,
.hbomax_icon,
.cmore_icon,
.appletvplus_icon {
  position: relative;
  &::before {
    height: 50px;
    width: 50px;
    border-radius: 3px;
    background: #fff;
    content: "";
    position: absolute;
    z-index: -1;
    top: -5px;
    left: -5px;
    @include respond-below(xs) {
      height: 40px;
      width: 40px;
    }
  }
}

.disneyplus_icon {
  &::before {
    top: -1px;
    @include respond-below(xs) {
      top: 2px;
    }
  }
}
.cmore_icon {
  &::before {
    background: #d8d8d8;
  }
}
.C_MORE_icon,
.cmore_icon {
  background: url("../images/provider/cMore.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_NORDIC_icon,
.hbo_nordic,
.hbo_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.DISCOVERY_PLUS_icon,
.dplay_icon {
  background: url("../images/provider/discoveryplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_MAX_icon,
.hbomax_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.APPLE_TV_icon,
.appletv_icon {
  background: url("../images/appleTv.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.viafree_icon {
  background: url("../images/provider/viafree.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plejmo_icon {
  background: url("../images/plejmo-icon.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.tv4play_icon {
  background: url("../images/fyran-logo.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.svtplay_icon {
  background: url("../images/provider/svt.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.sfanytime_icon {
  background: url("../images/provider/sftime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.streamly_icon {
  background: url("../images/streamly-text-logo.svg") 0 0 no-repeat;
  height: 70px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.rakutentv_icon {
  background: url("../images/rakutenTV.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.rakutentv_full_icon {
  background: url("../images/rakutenTV.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.skyshowtime_icon {
  background: url("../images/SkyShowtime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.skyshowtime_full_icon {
  background: url("../images/SkyShowtime.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.britbox_icon {
  background: url("../images/britBox.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.britbox_full_icon {
  background: url("../images/britBox.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.blockbuster_icon {
  background: url("../images/blockbuster.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.blockbuster_full_icon {
  background: url("../images/blockbuster.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plutotv_icon {
  background: url("../images/plutoTV.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plutotv_full_icon {
  background: url("../images/plutoTV.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plutotv_icon {
  background: url("../images/plutoTV.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plutotv_full_icon {
  background: url("../images/plutoTV.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.appletvplus_icon {
  background: url("../images/provider/appletvplus.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  // border-radius: 5px;
  vertical-align: middle;
  &::before {
    left: 0;
    top: 0;
  }
  @include respond-below(xs) {
    &::before {
      left: -5px;
      top: -5px;
    }
  }
}

.appletvplus_full_icon {
  background: url("../images/provider/appletvplus.png") 0 0 no-repeat;
  height: 52px;
  width: 52px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.disneyplus_full_icon {
  background: url("../images/provider/disneyplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.NETFLIX_full_icon,
.netflix_full_icon {
  background: url("../images/provider/netflixFull.png") 0 0 no-repeat;
  height: 35px;
  width: 90px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.PRIME_VIDEO_full_icon,
.primevideo_full_icon,
.amazonprimevideo_full_icon {
  background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.VIA_PLAY_full_icon,
.viaplay_full_icon {
  background: url("../images/provider/full_viaplay.png") 0 0 no-repeat;
  height: 35px;
  width: 90px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.viaplay_full_icon {
  width: 145px;
}
.C_MORE_full_icon,
.cmore_full_icon {
  background: url("../images/provider/cmore.png") 0 0 no-repeat;
  height: 30px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_NORDIC_full_icon,
.hbo_nordic_full,
.hbo_full_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.DISCOVERY_PLUS_full_icon,
.dplay_full_icon {
  background: url("../images/provider/discoveryplus.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_MAX_full_icon,
.hbomax_full_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.APPLE_TV_full_icon,
.appletv_full_icon {
  background: url("../images/appleTv.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.viafree_full_icon {
  background: url("../images/provider/viafree.png") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plejmo_full_icon {
  background: url("../images/plejmo-icon.svg") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.tv4play_full_icon {
  background: url("../images/provider/tv4playFull.png") 0 0 no-repeat;
  height: 35px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.svtplay_full_icon {
  background: url("../images/provider/svtplay.png") 0 0 no-repeat;
  height: 50px;
  width: 75px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.sfanytime_full_icon {
  background: url("../images/provider/sftime.png") 0 0 no-repeat;
  height: 50px;
  width: 50px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.streamly_full_icon {
  background: url("../images/streamly-text-logo.svg") 0 0 no-repeat;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.player-container {
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.streamly_icon {
  background: url("../images/streamly-text-logo.svg") 0 0 no-repeat;
  height: 70px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

$tumb-up-checked: "../images/thumb-up-checked.svg";
$tumb-up-unchecked: "../images/thumb-up-unchecked.svg";

$tumb-down-checked: "../images/thumb-down-checked.svg";
$tumb-down-unchecked: "../images/thumb-down-unchecked.svg";

.thumbs-vote {
  @include clearfix;
  margin: 11px 0;
  position: relative;
  .thumbs-up,
  .thumbs-down {
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    display: block;
    float: left;
    color: white;
    background-repeat: no-repeat;

    &:not(:first-child) {
      margin-left: 16px;
    }

    > span {
      margin-left: 35px;
      font-weight: bold;
      padding: 0 11px;
    }
  }

  .thumbs-up {
    background-image: url($tumb-up-unchecked);
    &.checked {
      background-image: url($tumb-up-checked);
    }
  }
  .thumbs-down {
    background-image: url($tumb-down-unchecked);
    &.checked {
      background-image: url($tumb-down-checked);
    }
  }

  &.voted-down {
    .thumbs-down {
      background-image: url($tumb-down-checked);
    }
  }

  &.voted-up {
    .thumbs-up {
      background-image: url($tumb-up-checked);
    }
  }

  a {
    text-decoration: none;
  }
}

.title-view {
  .thumbs-vote {
    float: right;
  }
}

.card-header {
  padding: 20px 0;
}

.accordion {
  border-top: solid 1px #666;
  .card {
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    background: none;
    border-bottom: solid 1px #666 !important;
    .btn {
      font-size: 30px;
      font-weight: 700;
      color: #fff;
      width: 100%;
      text-align: left;
      padding: 0;
      line-height: 30px;
      &:hover {
        text-decoration: none;
      }
      @include allMobiles {
        font-size: 24px;
        line-height: 24px;
      }
      &.extended {
        .indicator {
          background: transparent url("../images/arrow-up-white.svg") no-repeat
            right center;
        }
      }

      .indicator {
        font-size: 16px;
        float: right;
        margin: 0;
        line-height: 30px;
        padding-right: 20px;
        background: transparent url("../images/arrow-down-white.svg") no-repeat
          right center;
      }
    }
  }
}

// Search block
.search-block {
  text-align: center;
  padding: 10px;
  margin-top: 30px;
  h2 {
    font-size: 32px;
    font-weight: $bold;
    border: 0;
    margin: 0 0 5px 0;
    padding: 0;
    color: #fff;
  }

  p {
    font-size: 14px;
    margin: 0 0 30px 0;
  }

  form {
    position: relative;
    width: auto;
    margin: 0 auto;
    max-width: 600px;

    input[type="text"] {
      min-height: 46px;
      border-radius: 99999px;
      font-weight: $regular;
      font-size: 22px;
      padding-left: 60px;
    }

    input[type="submit"] {
      background: transparent url("../images/search-light-gray.svg") no-repeat
        center center;
      border: 0;
      text-indent: -99999px;
      position: absolute;
      width: 27px;
      height: 27px;
      left: 20px;
      top: 10px;
      margin: 0;
    }
  }
}

.sidebar {
  margin-top: 45px;
}

.sidebar-nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      line-height: 55px;
      height: 55px;
      width: 110px;
      border-bottom: solid 1px #bfbfbf;
      a {
        padding: 0;
        color: #575757;
        &:hover {
          color: #1e9cbc;
        }
        &.active {
          font-weight: bold;
          color: #1e9cbc;
        }
      }
    }
  }
}

.static-content {
  .content-block {
    margin-bottom: 20px;
    width: 100%;
    padding: 0 15px 15px 15px;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    @include clearfix;

    &:first-child {
      margin-top: 10px;
    }

    .content-block-header {
      font-weight: $light;
      border-bottom: solid 1px rgba(102, 102, 102, 1);
      margin: 0 0 25px 0;
      padding-bottom: 10px;
    }
    p,
    ul {
      margin-bottom: 20px;
    }
    p,
    ul li {
      font-size: 18px;
      line-height: 24px;
      color: #fff;
    }
    h4 {
      font-size: 20px;
      margin: 20px 0;
      font-weight: $light;
    }
    h1 {
      @extend .content-block-header;
      font-size: 46px;

      &.with-sub {
        line-height: 0.7em;

        sub {
          bottom: 0;
          font-size: 14px;

          a {
            padding-right: 21px;
            background: url("../images/arrow-teal-right.svg") no-repeat right;
            font-weight: bold;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    h2 {
      @extend .content-block-header;
      //font-size: 24px;
      font-size: 30px;
      font-weight: $bold;
    }
  }
}

.rerun-modal {
  h5 {
    font-size: 18px;
  }
  .text {
    font-size: 15px;
    margin-left: 20px;
  }
}

.top-add-container.sticky {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.f__h1,
.h1,
h1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  word-break: break-word;
}

@media (min-width: 768px) {
  .f__h1,
  .h1,
  h1 {
    font-size: 2.25rem;
  }
}

.f__h2,
.h2,
h2 {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: normal;
  word-break: break-word;
}

@media (min-width: 768px) {
  .f__h2,
  .h2,
  h2 {
    font-size: 1.625rem;
  }
}

.f__h3,
.h3,
h3 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.625rem;
  word-break: break-word;
}

@media (min-width: 768px) {
  .f__h3,
  .h3,
  h3 {
    font-size: 1.75rem;
    line-height: 1.875rem;
  }
}

.f__h4,
.h4,
h4,
.f__h5,
.h5,
h5 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.375rem;
  word-break: break-word;
}

@media (min-width: 768px) {
  .f__h4,
  .h4,
  h4,
  .f__h5,
  .h5,
  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #000000;
  color: #ffffff;
  font-family: "proxima-nova", sans-serif;
  font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1.63;
  margin: 0 0 0 0;
  max-width: 100%;
  min-width: 300px;
  padding: 0 0 0 0;
  position: relative;
  text-rendering: optimizeLegibility;
  width: 100%;
}

body *,
body *:before,
body *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.element-button-wrapper {
  display: block;
  float: left;
  margin: -5px -5px -5px -5px;
  min-width: 100%;
  position: relative;
  text-align: center;
  z-index: 10;
}

.element-button-wrapper .element-button {
  clear: none;
  margin: 5px 5px 5px 5px;
}

.element-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #25e6ed;
  border-radius: 1.4375rem;
  border-width: 0;
  clear: both;
  color: #000000;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.0625rem;
  font-weight: 500;
  letter-spacing: 0.05625rem;
  line-height: 0.875rem;
  margin: 0 0 0 0;
  max-width: 100%;
  max-width: none;
  min-height: 2.5rem;
  padding: 0.8125rem 3rem 0.75rem 3rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  /*
		solid
	*/
}

.element-button:focus {
  outline: none;
}

.element-button:active {
  background-color: darkgrey;
}

.element-button:disabled:hover,
.element-button:disabled:focus,
.element-button:disabled:active,
.element-button.is--disabled:hover,
.element-button.is--disabled:focus,
.element-button.is--disabled:active {
  cursor: not-allowed;
}

.element-button.has--icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 24px 0 44px;
  white-space: nowrap;
}

.element-button.has--icon .icon {
  left: -13px;
  margin-left: -7px;
  position: relative;
  top: -1px;
}

@media (min-width: 768px) {
  .element-button {
    width: auto;
  }
}

.element-button.is--solid,
.element-button.is--solid--default,
.element-button.is--solid--black {
  background-color: black;
  color: #ffffff;
}

.element-button.is--solid:disabled,
.element-button.is--solid:disabled:hover,
.element-button.is--solid:disabled:focus,
.element-button.is--solid:disabled:active,
.element-button.is--solid.is--disabled,
.element-button.is--solid.is--disabled:hover,
.element-button.is--solid.is--disabled:focus,
.element-button.is--solid.is--disabled:active,
.element-button.is--solid--default:disabled,
.element-button.is--solid--default:disabled:hover,
.element-button.is--solid--default:disabled:focus,
.element-button.is--solid--default:disabled:active,
.element-button.is--solid--default.is--disabled,
.element-button.is--solid--default.is--disabled:hover,
.element-button.is--solid--default.is--disabled:focus,
.element-button.is--solid--default.is--disabled:active,
.element-button.is--solid--black:disabled,
.element-button.is--solid--black:disabled:hover,
.element-button.is--solid--black:disabled:focus,
.element-button.is--solid--black:disabled:active,
.element-button.is--solid--black.is--disabled,
.element-button.is--solid--black.is--disabled:hover,
.element-button.is--solid--black.is--disabled:focus,
.element-button.is--solid--black.is--disabled:active {
  background-color: #868e96;
}

.element-button.is--solid:hover,
.element-button.is--solid--default:hover,
.element-button.is--solid--black:hover {
  background-color: black;
}

.element-button.is--solid:active,
.element-button.is--solid--default:active,
.element-button.is--solid--black:active {
  background-color: black;
}

.element-button.is--solid--transparent {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.element-button.is--solid--transparent:disabled,
.element-button.is--solid--transparent:disabled:hover,
.element-button.is--solid--transparent:disabled:focus,
.element-button.is--solid--transparent:disabled:active,
.element-button.is--solid--transparent.is--disabled,
.element-button.is--solid--transparent.is--disabled:hover,
.element-button.is--solid--transparent.is--disabled:focus,
.element-button.is--solid--transparent.is--disabled:active {
  background-color: #868e96;
}

.element-button.is--solid--transparent:hover {
  background-color: #e6e6e6;
}

.element-button.is--solid--transparent:active {
  background-color: #cccccc;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.element-input.is--invalid {
  background-color: #fff8f8;
  border-color: #ff6b6b;
}

.element-input__description {
  display: inline-block;
}

.element-input,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"] {
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: black;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 40px;
  line-height: 1;
  margin: 0 0 0 0;
  overflow: hidden;
  padding: 12px;
  text-overflow: ellipsis;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  white-space: nowrap;
  width: 100%;
}

.element-input:-moz-read-only:not([read-only="false"]),
input[type="text"]:-moz-read-only:not([read-only="false"]),
input[type="password"]:-moz-read-only:not([read-only="false"]),
input[type="date"]:-moz-read-only:not([read-only="false"]),
input[type="datetime"]:-moz-read-only:not([read-only="false"]),
input[type="datetime-local"]:-moz-read-only:not([read-only="false"]),
input[type="month"]:-moz-read-only:not([read-only="false"]),
input[type="week"]:-moz-read-only:not([read-only="false"]),
input[type="email"]:-moz-read-only:not([read-only="false"]),
input[type="number"]:-moz-read-only:not([read-only="false"]),
input[type="tel"]:-moz-read-only:not([read-only="false"]),
input[type="time"]:-moz-read-only:not([read-only="false"]),
input[type="url"]:-moz-read-only:not([read-only="false"]),
input[type="color"]:-moz-read-only:not([read-only="false"]) {
  color: #b1b1b1;
}

.element-input:read-only:not([read-only="false"]),
input[type="text"]:read-only:not([read-only="false"]),
input[type="password"]:read-only:not([read-only="false"]),
input[type="date"]:read-only:not([read-only="false"]),
input[type="datetime"]:read-only:not([read-only="false"]),
input[type="datetime-local"]:read-only:not([read-only="false"]),
input[type="month"]:read-only:not([read-only="false"]),
input[type="week"]:read-only:not([read-only="false"]),
input[type="email"]:read-only:not([read-only="false"]),
input[type="number"]:read-only:not([read-only="false"]),
input[type="tel"]:read-only:not([read-only="false"]),
input[type="time"]:read-only:not([read-only="false"]),
input[type="url"]:read-only:not([read-only="false"]),
input[type="color"]:read-only:not([read-only="false"]) {
  color: #b1b1b1;
}

.element-input::-ms-clear,
input[type="text"]::-ms-clear,
input[type="password"]::-ms-clear,
input[type="date"]::-ms-clear,
input[type="datetime"]::-ms-clear,
input[type="datetime-local"]::-ms-clear,
input[type="month"]::-ms-clear,
input[type="week"]::-ms-clear,
input[type="email"]::-ms-clear,
input[type="number"]::-ms-clear,
input[type="tel"]::-ms-clear,
input[type="time"]::-ms-clear,
input[type="url"]::-ms-clear,
input[type="color"]::-ms-clear {
  display: none;
}

.element-input:not(.is--invalid):focus,
input[type="text"]:not(.is--invalid):focus,
input[type="password"]:not(.is--invalid):focus,
input[type="date"]:not(.is--invalid):focus,
input[type="datetime"]:not(.is--invalid):focus,
input[type="datetime-local"]:not(.is--invalid):focus,
input[type="month"]:not(.is--invalid):focus,
input[type="week"]:not(.is--invalid):focus,
input[type="email"]:not(.is--invalid):focus,
input[type="number"]:not(.is--invalid):focus,
input[type="tel"]:not(.is--invalid):focus,
input[type="time"]:not(.is--invalid):focus,
input[type="url"]:not(.is--invalid):focus,
input[type="color"]:not(.is--invalid):focus {
  outline: none;
  border-color: #98a0a9;
}

.element-input:placeholder,
input[type="text"]:placeholder,
input[type="password"]:placeholder,
input[type="date"]:placeholder,
input[type="datetime"]:placeholder,
input[type="datetime-local"]:placeholder,
input[type="month"]:placeholder,
input[type="week"]:placeholder,
input[type="email"]:placeholder,
input[type="number"]:placeholder,
input[type="tel"]:placeholder,
input[type="time"]:placeholder,
input[type="url"]:placeholder,
input[type="color"]:placeholder {
  color: black;
}

/*
<a href="#" class="element-link element-link--arrow">Fancy icon link</a>
*/
.element-link {
  color: black;
  cursor: pointer;
  font-weight: 700;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  text-decoration: underline;
}

.element-link:hover,
.element-link:active {
  color: steelblue;
}

.element-link--arrow {
  margin-right: 8px;
  max-width: 100%;
  padding-right: 22px;
  position: relative;
}

.element-link--arrow::after {
  content: "";
  background-image: url("https://cdn.onlinewebfonts.com/svg/img_447773.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  margin: 0 0 0 10px;
  position: absolute;
  -webkit-transition: margin 0.15s ease-in-out;
  transition: margin 0.15s ease-in-out;
  width: 1.375rem;
}

.element-link--arrow:hover::after {
  margin: 0 0 0 5px;
}

.element-link--no-decoration {
  color: black;
  cursor: pointer;
  font-weight: 700;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  text-decoration: none;
}

.element-link--no-decoration:hover,
.element-link--no-decoration:active {
  color: steelblue;
}

.element-link--no-decoration:hover,
.element-link--no-decoration:active {
  color: steelblue;
}

.icon {
  position: relative;
}

.streamly-play {
  text-align: center;
  min-height: 100vh;
  padding: 2rem 0 3.375rem 0;
}

.streamly-play h1,
.streamly-play h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .streamly-play h1,
  .streamly-play h2 {
    max-width: 580px;
    margin-right: auto;
    margin-left: auto;
  }
}

.streamly-play h1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  word-break: break-word;
}

@media (min-width: 768px) {
  .streamly-play h1 {
    font-size: 2.25rem;
  }
}

.streamly-play h1 + * {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .streamly-play h1 + * {
    margin-top: 3rem;
  }
}

.streamly-play h2 {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: normal;
  word-break: break-word;
  font-size: 1.375rem;
}

@media (min-width: 768px) {
  .streamly-play h2 {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) {
  .streamly-play h2 {
    font-size: 1.625rem;
  }
}

.streamly-play h1 > *,
.streamly-play h2 > * {
  margin-top: 1.5rem;
}

.streamly-play h1 > a,
.streamly-play h2 > a {
  margin-top: 1rem;
}
.streamly-play footer small a {
  font-size: 12px;
}

.streamly-play p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  text-align: center;
}

@media (min-width: 768px) {
  .streamly-play p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.streamly-play a {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  color: #25e6ed;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 768px) {
  .streamly-play a {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.streamly-play a:hover {
  text-decoration: underline;
}

.streamly-play hr {
  background-color: rgba(255, 255, 255, 0.15);
  border: 0;
  height: 0.0625rem;
  margin: 0 15px;
  max-width: 580px;
  width: calc(100% - 15px * 2);
}

@media (min-width: 768px) {
  .streamly-play hr {
    margin: 0 auto;
  }
}

.streamly-play > .wrap {
  width: calc(100% - 15px * 2);
  min-width: 290px;
  max-width: 946px;
  margin: 0 auto;
}

.streamly-play > .container {
  width: 100%;
  margin: 1.5rem 0.9375rem;
}

.streamly-play > .container:first-of-type {
  margin-top: 0;
}

.streamly-play > .container:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .streamly-play > .container {
    margin: 3.375rem auto;
  }

  .streamly-play > .container:first-of-type {
    margin-bottom: 2.5rem;
  }
}

.streamly-play > .wrap .element-button {
  color: #ffffff;
}

.streamly-play > .wrap > .container > p {
  max-width: 580px;
  margin-right: auto;
  margin-left: auto;
}

.streamly-play > .wrap > .container + .container,
.streamly-play > .wrap > .container + .ad-block,
.streamly-play > .wrap > .ad-block + .container {
  margin-top: 3.375rem;
}

@media (min-width: 768px) {
  .streamly-play > .wrap > .container + .container,
  .streamly-play > .wrap > .container + .ad-block,
  .streamly-play > .wrap > .ad-block + .container {
    margin-top: 5rem;
  }
}

.streamly-play > .wrap > hr + .container {
  margin-top: 1.5rem;
}

.ad-block {
  padding: 0 1.69492% 1.69492% 1.69492%;
  margin: 30px 0;
  text-align: center;
}

.ad-block .ad-text {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 4px;
  text-align: left;
  color: #aaa;
}

.ad-block .ad-mobile img,
.ad-block .ad-tablet img,
.ad-block .ad-desktop img {
  max-width: 100%;
}

.ad-block.nocenter {
  text-align: left;
}

.ad-block .ad-mobile {
  display: inline-block;
}

.ad-block .ad-tablet {
  display: none;
}

.ad-block .ad-desktop {
  display: none;
}

.ad-pagination {
  padding-bottom: 30px;
  text-align: center;
}

.ad-pagination .ad-pagination-block {
  display: none;
}

.content-block .ad-block {
  padding: 0;
}

@media (min-width: 768px) and (max-width: 1090px) {
  .ad-block .ad-mobile {
    display: none;
  }

  .ad-block .ad-tablet {
    display: inline-block;
  }

  .ad-block .ad-desktop {
    display: none;
  }

  .ad-pagination .ad-pagination-block {
    display: inline-block;
  }
}

@media (min-width: 1090px) {
  .ad-block .ad-mobile {
    display: none;
  }

  .ad-block .ad-tablet {
    display: none;
  }

  .ad-block .ad-desktop {
    display: inline-block;
  }

  .ad-pagination .ad-pagination-block {
    display: inline-block;
  }
}

.article-list {
  list-style: none;
  padding-left: 0;
  margin: 2.375rem 0 0 0;
}

@media (min-width: 768px) {
  .article-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -2.5rem;
  }
}

.article-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .article-list__item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin-bottom: 2.5rem;
  }

  .article-list__item:nth-child(odd) {
    padding-right: 0.75rem;
  }

  .article-list__item:nth-child(even) {
    padding-left: 0.75rem;
  }
}

.article-list__item + .article-list__item {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .article-list__item + .article-list__item {
    margin-top: 0;
  }
}

.article-list__item > .image {
  width: 30%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 17%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.article-list__item-inner {
  text-align: left;
}

.article-list__item-inner > .link,
.article-list__item-inner > .text {
  text-align: left;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

.article-list__item-inner > .link:hover,
.article-list__item-inner > .text:hover {
  text-decoration: underline;
}

.article-list__item-inner > .text {
  color: rgba(255, 255, 255, 0.58);
  font-size: 0.875rem;
  line-height: 1.57;
  margin: 0.5rem 0 0 0;
  width: 100%;
}

@media (max-width: 767px) {
  .article-list__item-inner > .text {
    display: none;
  }
}

.article-list__item > .image + .article-list__item-inner {
  margin-left: 1rem;
}

.countdown-timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0;
}

.countdown-timer__heading {
  color: rgba(255, 255, 255, 0.58);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.countdown-timer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;
  width: 100%;
  max-width: 345px;
  min-width: 320px;
}

.countdown-timer__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 4.75rem;
  margin: 0 1rem;
  position: relative;
}

.countdown-timer__list-item
  + .countdown-timer__list-item
  > .countdown-timer__digit::before {
  content: ":";
  left: -1.25rem;
  position: absolute;
  text-align: center;
  width: 0.5rem;
}

.countdown-timer__digit {
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .countdown-timer__digit {
    font-size: 2.25rem;
  }
}

.countdown-timer__label {
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .countdown-timer__label {
    font-size: 0.875rem;
  }
}

.form .form-element-wrapper {
  padding: 0 0 20px 0;
  position: relative;
}

.form .element-form-description {
  display: inline-block;
}

.form .align-bottom .form-element-wrapper {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.validation-text--error {
  right: 0;
  text-align: right;
  bottom: 0;
  color: #e03131;
  font-size: 0.875rem;
  font-weight: normal;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 1.57;
  padding-top: 8px;
  position: absolute;
  width: 100%;
}

.is--invalid ~ .validation-text--error,
.not--hidden.validation-text--error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.video {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .video {
    margin-top: 0;
  }
}

.video > *:first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video iframe {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.video__loader .video {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 56.25% 0 0 0;
  position: relative;
  cursor: pointer;
}

.video__loader .video::before {
  content: "Klicka för att spela";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.video__wrapper {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .video__wrapper {
    -ms-flex-preferred-size: calc(50% - 0.75rem);
    flex-basis: calc(50% - 0.75rem);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .video__wrapper + .video__wrapper {
    margin-left: 1.5rem;
  }
}

.video__container:not(.is--hero):not(.video__loader) .video__wrapper {
  max-width: 752px;
}

@include respond-below(xs) {
  .modal-overlay {
    .modal-inner-container {
      max-width: 100%;
    }
  }
  .show-mylist {
    display: block;
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .info-bar {
    .col-xs-6 {
      width: 50%;
    }
    .filter {
      label {
        margin: 0;
      }
    }
    .category-drop-down {
      margin: 0 0 5px 0;
      width: 105px;
    }
  }
  .main-content {
    .container {
      padding-top: 0;
    }
  }
}

@include smallDesktop {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }
    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }
}

@include smallDesktopAndMobile {
  .user-container {
    padding: 0 30px;
  }
  .user-container .my-list {
    max-width: 80%;
    // margin: 0 auto;
  }
  .col-md-10.noPadding.noPadding.noPadding {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

@include tablet {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }
    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }
}

.sticky-top {
  z-index: 998 !important;
}

.no-height.no-height.no-height {
  height: 0;
}

@include allMobiles {
  .myProgramList-modal.myProgramList-modal.myProgramList-modal {
    width: 90%;
    .my-list {
      border-radius: 0;
    }
  }
  .info-bar {
    height: 45px;
  }
  .show-mylist {
    display: block;
    text-align: right;
    position: absolute;
    width: 195px;
    right: 0;
    top: 5px;
    .showlist {
      margin: 5px;
    }
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .static-page {
    h3 {
      font-size: 1.2rem;
    }
  }
  .user-container .my-list {
    max-width: 80%;
  }
  .timeline-layout {
    padding: 0 20px;
    .paddingLeft0 {
      padding: 0;
    }
  }
  .program-list {
    margin: 0;
    justify-content: center;
  }
  h2 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  .setting-page {
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-md-10.noPadding.noPadding.noPadding {
    padding: 0;
  }
  .search-result {
    .action-bar {
      .filters {
        padding: 5px 10px;
        .label,
        select {
          margin-right: 10px;
        }
        select {
          width: 125px;
        }
      }
      .heading {
        padding: 0 10px;
      }
    }
    .search-list {
      li {
        height: auto;
        width: 100%;
        position: relative;
        line-height: 40px;
        padding-bottom: 5px;
        .anchor-link {
          clear: both;
          float: none;
          width: 100%;
          line-height: 20px;
        }
        .logo {
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }
  }
  .search-active {
    padding: 0 15px;
  }
  .welcome-message {
    padding: 20px 20px 5px 10px;
    i {
      margin-right: 20px;
    }
    .text {
      width: calc(100% - 90px);
      p {
        margin-bottom: 10px;
      }
    }
  }
  .sidebar-nav {
    display: none;
  }
  .top-add-container {
    height: 480px;
    min-height: 570px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    .ad-placeholder{
      min-height: 570px;
      // background: #e8ecf1;
      width: 320px;
      min-width: 320px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      > div,
      #cncpt-mob1 {
        position: sticky;
        top: 66px !important;
        margin: 0 !important;
        width: 100%;
      }
    }
    
  }
  .main-content {
    &.container {
      padding-bottom: 20px;
    }
  }
  
  .grid-add-container-outer {
    // height: 480px;
    background: rgb(190, 189, 189);
    width: 100%;
    position: relative;
  }
  .grid-add-container {
    //   height: 480px;
    //   transition: all .5s ease-in;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
    width: 100%;
    position: relative;
    //   border:1px solid red;
  }
  .grid-add-container-height {
    height: 320px;
  }
}



.outer-container{
  position: relative;
}
.adnm-topscroll .sidebar{
  top:0;
}

#adscontainer{
  width: 100%;
}
.ad-label{
  color: #666;
  font-size: 14px;
  position: relative;
  padding: 0 0px;
  width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  margin-bottom: 5px;
  min-width: 120px;
  &:before {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    background: #ccc;
    top:10px;
  }
  span{
    padding: 0 10px;
    background: #fff;
    position: relative;
    z-index: 1;
  }
}
.popover-context-menu {
  background: $gray-41;
  width: 260px;
  height: 180px;
  padding: 0;
  display: none;
  border-radius: 5px;
  position: absolute;
  top: 45px;
  z-index: 10;
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
    right: auto;
  }

  &.active {
    display: block;
  }
  .close-btn {
    background: url(/images/close.svg) no-repeat 0 4px;
    height: 22px;
    width: 15px;
    background-size: 100% 100%;
    display: block;
    position: absolute;
    right: -1px;
    z-index: 1;
    top: -7px;
    cursor: pointer;
  }

  &.menuLeft {
    &:after {
      left: 20px;
    }
  }

  .popover-container {
    cursor: pointer;
    /*display: none;*/
    margin: 10px;
    padding: 0;
    height: 160px;
    //overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $gray-41;
    text-align: center;
    width: 240px;

    div {
      position: relative;
      top: 50%;
      @include transform(translateY(-50%));

      &.success {
        display: none;
      }

      &.error {
        display: none;
      }

      &.remove {
        display: none;
      }
    }

    .feedback-label {
      font-size: 14px;
      color: $gray-117;
      margin: 0;
      padding: 0;
    }

    .feedback-text {
      font-weight: $bold;
      color: $gray-117;
    }

    .feedback-icon {
      width: 36px;
      height: 36px;
      background: url("../images/checked-ring-gray.svg") no-repeat 0 0;
      background-size: 100% 100%;
      display: inline-block;
    }
    .non-logged-in-icon {
      width: 36px;
      height: 36px;
      background: url("../images/warning-gray.svg") no-repeat 0 0;
      background-size: 100% 100%;
      display: inline-block;
    }

    .feedback-register-button,
    .feedback-login-button {
      @include appearance(none);
      border: 0;
      background: transparent;
      font-weight: 400;
      color: #ffffff;
    }

    .feedback-register-button {
      padding: 1px 6px;
      color: white;

      &:link {
        text-decoration: none;
      }
    }
  }
}

@mixin ani {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@mixin anislow {
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
}

.slider-parent {
  padding-left: 30px;
  margin-bottom: 30px;
  &.homePage-listing{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
  > div{
    margin: 0 7px 15px;
    width: calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
    min-height: 120px;
    overflow: visible;
    @include allMobiles {
      width: 100%;
      max-width: 100%;
      margin-bottom: 7px;
    }
    @include tablet {
      margin: 0 3px 6px;
      width: calc(33.33% - 6px);
      max-width: calc(33.33% - 6px);
      .item{
        margin: 0;
      }
    }
  
  }

  }

  @include allMobiles {
    padding-left: 0;
    margin-bottom: 50px;
  }
  @include tablet {
    padding-left: 0;
    margin-bottom: 0;
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    margin: 1px;
    height: inherit !important;
    > div {
      height: 100% !important;
    }
  }

  .slick-prev {
    width: 50px;
    height: calc(100% - 60px);
    background: rgba(0, 0, 0, 0.7);
    @include allMobiles {
      width: 20px;
      height: 30px;
    }
    @include tablet {
      width: 20px;
      height: 30px;
    }
    &:before {
      top: 40%;
      position: absolute;
      height: 30px;
      width: 30px;
      left: 10px;
      background-size: contain;
      @include allMobiles {
        top: 8px;
        height: 15px;
        width: 15px;
        left: 4px;
      }
      @include tablet {
        top: 8px;
        height: 15px;
        width: 15px;
        left: 4px;
      }
    }
  }
  .slick-next {
    width: 50px;
    height: calc(100% - 60px);
    background: rgba(0, 0, 0, 0.9);
    @include allMobiles {
      width: 20px;
      height: 30px;
    }
    @include tablet {
      width: 20px;
      height: 30px;
    }
    &:before {
      top: 40%;
      position: absolute;
      height: 30px;
      width: 30px;
      right: 0;
      background-size: contain;
      @include allMobiles {
        top: 8px;
        height: 15px;
        width: 15px;
        right: -2px;
      }
      @include tablet {
        top: 8px;
        height: 15px;
        width: 15px;
        right: -2px;
      }
    }
  }

  button.slick-disabled {
    opacity: 0 !important;
  }

  .flickfeed {
    opacity: 0;
  }
  .slick-initialized {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  .flix-parents {
    overflow: hidden;
  }

  .flix-parents {
    position: relative;
    z-index: 5;
  }
  .flixer {
    position: relative;
    z-index: 5;
    height: 200px;
  }

  .video-list-slider {
    background: 0 0;
    border: none;
    border-radius: 0;
    overflow: visible;
  }

  .slick-list {
    overflow: visible;
    @include allMobiles {
      padding-left: 10px;
    }
    @include tablet {
      padding-left: 10px;
    }
  }

  .flickfeed .slick-slide .item {
    border-radius: 0px;
    @include anislow;
    padding-top: 55%;
    background-size: cover;
    background-repeat: no-repeat;
    border: 0;
    margin: 0;
    @include allMobiles {
      padding: 0 5px;
      min-height: 150px;
      background-position: top right;
      background-origin: content-box;
      border-radius: 5px;
    }
  }

  .slick-slide {
    @include anislow;
    &:hover {
      transform: scale(1.2);
      z-index: 3;
      @include allMobiles {
        transform: none;
      }
      @include tablet {
        transform: none;
      }
    }
    &:first-child {
      &:hover {
        transform-origin: center left !important;
        @include allMobiles {
          transform: none;
        }
        @include tablet {
          transform: none;
        }
      }
    }
    &:last-child {
      &:hover {
        transform-origin: center right !important;
        @include allMobiles {
          transform: none;
        }
        @include tablet {
          transform: none;
        }
      }
    }
  }
  .prevslides {
    transform: translateX(-3.95vw);
    @include allMobiles {
      transform: none;
    }
    @include tablet {
      transform: none;
    }
  }

  .nextslides {
    transform: translateX(3.95vw);
    @include allMobiles {
      transform: none;
    }
    @include tablet {
      transform: none;
    }
  }

  .furthernextslides {
    transform: translateX(7.9vw);
    @include allMobiles {
      transform: none;
    }
    @include tablet {
      transform: none;
    }
  }
  .furtherprevslides {
    transform: translateX(-7.9vw);
    @include allMobiles {
      transform: none;
    }
    @include tablet {
      transform: none;
    }
  }

  .firster {
    transform-origin: center left !important;
  }
  .laster {
    transform-origin: center right !important;
  }

  .slick-track {
    &:first-child {
      transform-origin: center left !important;
    }
    &:last-child {
      transform-origin: center right !important;
    }
  }
  .flickfeed .item {
    position: relative;
    overflow: hidden;
    &::before {
      padding: 0;
    }

    .overlay {
      background-image: none;
      padding: 0 7px 7px 7px;
    }
    .back {
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.7) 10%,
        rgba(0, 0, 0, 0)
      );
      background-color: transparent;
      top: 0;
      @include allMobiles {
        left: 0;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8) 30%,
          rgba(0, 0, 0, 0)
        );
      }
      @include tablet {
        left: 0;
      }
      @include anislow;
      h1,
      p {
        margin: 0;
        color: #fff;
      }
    }

    &:hover {
      .uppName {
        visibility: hidden;
      }
      .back {
        top: 0;
      }
    }
  }

  .back .h4 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .back p {
    font-size: 12px;
    opacity: 0.9;
  }

  button.more {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 10%;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E");
    left: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size: 0px;

    background-color: transparent;
    border: 0;
    outline: 0;
  }

  @include allMobiles {
    .slick-slider .slick-slide.slick-current,
    .slick-slider .slick-slide.slick-center {
      transform: scale(1.3);
      margin-right: 0.6%;
      margin-left: 0.6%;
    }
  }
}




.listingPage{
  .homePage-listing{
    > div{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 24%;
      max-width: 24%;
      margin: 0 0.5% 1%;
      background-color: #ebe7e7;
      @include allMobiles {
        flex: 1 0 100%;
        max-width: 100%;
      }
      @include tablet {
        flex: 1 0 calc(50% - 1.9%);
        max-width: calc(50% - 1.9%);
      }
      // .relative{
      //   border:1px solid red;
      // }
      .item{
        margin: 0;
        padding:0;
      }
    }
  }
}











.uppName {
  // @include anislow;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0;
  left: 0;
  padding: 10px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
  background-color: transparent;
  @include allMobiles {
    font-size: 12px;
    padding: 15px 5px 5px 5px;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0)
    );
  }
}
.pagination {
  justify-content: center;
  margin-top: 20px;
  @include allMobiles {
    display: block;
    text-align: center;
  }
  li {
    display: inline-block;
    text-align: center;
    font-weight: 700;
    color: #757575;
    width: 50px;
    height: 50px;
    position: relative;
    vertical-align: middle;
    @include allMobiles {
      width: 30px;
      height: 30px;
    }
    @include tablet {
      width: 30px;
      height: 30px;
    }
    a {
      width: 50px;
      height: 50px;
      display: block;
      color: #757575;
      font-size: 18px;
      text-decoration: none;
      line-height: 50px;
      cursor: pointer;
      @include allMobiles {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
      @include tablet {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
      }
    }
    &.active {
      a {
        color: #fff;
        border: solid 2px #fff;
        border-radius: 50px;
        line-height: 48px;

        @include allMobiles {
          line-height: 28px;
        }
        @include tablet {
          line-height: 28px;
        }
      }
    }
    &.previous {
      a {
        background-color: transparent;
        background-image: url(../images/arrow-left-gray.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 6px auto;
        position: relative;
        text-indent: -9999px;
        overflow: hidden;
      }
    }
    &.next {
      a {
        background-color: transparent;
        background-image: url(../images/arrow-right-gray.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 6px auto;
        position: relative;
        text-indent: -9999px;
        overflow: hidden;
      }
    }
  }
}

.sliderSection {
  margin: 0 -15px;
  h2 {
    font-size: 20px;
    color: #575757;
  }
  .program-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    > div {
      margin: 0 7px 15px;
      width: calc(25% - 14px);
      max-width: calc(25% - 14px);
      min-height: 120px;
      overflow: visible;
      @include tablet {
        width: calc(33.33% - 14px);
      max-width: calc(33.33% - 14px);
      }
      @include allMobiles {
        width: calc(100% - 14px);
      max-width: calc(100% - 14px);
      }
      .item{
        width: 100%;
      max-width: 100%;
      }
      
  }
  }

}

.homeSlider {
  position: relative;
  margin: 0 auto;
  max-width: 1600px;
  margin-bottom: 0;
  overflow: hidden;
  .item {
    max-height: 520px;
    cursor: pointer;
    text-align: right;
    min-height: 350px;
    background-size: cover;
    background-position: top center;
    @include tablet {
      max-height: 350px;
    }
    img {
      width: 100%;
      display: inline-block;
      border-radius: 0;
      object-fit: cover;
    }
    .overlay {
      border-radius: 0;
      .info {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 15px;
        padding: 0 0 0 20px;
        @include allMobiles {
          padding: 0 0 0 5px;
        }
        .provider-badge {
          display: inline-block;
          margin-left: 5px;
          background-color: #fff;
          color: #000;
          padding: 3px 5px;
          font-size: 13px;
          font-weight: 700;
          text-transform: capitalize;
          @include allMobiles {
            padding: 2px 3px;
            font-size: 11px;
          }
          &:hover {
            background: #2e2e2e;
            color: #fff;
          }
        }
      }
    }
  }

  .item .overlay span {
    margin-bottom: 20px;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    bottom: 25px;
    li {
      height: 15px;
      width: 15px;
      display: inline;
      button:before {
        font-size: 15px;
      }
    }
    @include allMobiles {
      display: none !important;
    }
  }
}
.main-inner-container {
  // overflow: hidden;
  min-height: 100%;
}
.user-action {
  padding: 8px;
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 40px;
  z-index: 1;
  a {
    margin: 0 8px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      -o-transform: scale(1.3);
      transform: scale(1.3);
    }
  }
}

.shorting.shorting.shorting {
  margin-top: 20px;
  display: flex;
  padding: 20px 0 0 0;
  border-top: 1px solid #747474;
  .col-md-9 {
    display: flex;
    align-items: center;
    padding: 0;
    > a {
      color: #747474;
      font-size: 16px;
      text-decoration: none;
      display: inline-block;
      margin-right: 20px;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
}

.static-banner {
  max-width: 100%;
  height: 600px;
  background-image: url(https://image.tmdb.org/t/p/w1920_and_h800_multi_faces/A7AoNT06aRAc4SV89Dwxj3EYAgC.jpg);
  background-size: cover;
  position: relative;
  padding: 0 !important;
  // margin-top: 80px;
  @include tablet {
    height: 400px;
  }
  @include allMobiles{
    display: none;
  }
  .banner-details {
    // position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7) 10%,
      rgba(0, 0, 0, 0)
    );
    background-color: transparent;
    top: 0;
    transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
    padding: 0 0 30px 30px;
  }
  .item-name {
    font-size: 3.5vw;
    line-height: 3.5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    @include tablet{
     margin-bottom: 0;
     }
  }
  .netflix_full {
    height: 70px;
    width: 160px;
  }

  .viaplay_full{
    background: url("../images/provider/full_viaplay.png") 0 0 no-repeat;
  height: 35px;
  width: 150px;
  }



  .amazonprimevideo_full{
    background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
    height: 66px;
    width: 90px;
  }


  .disneyplus_full{
    background-image: url("../images/provider/disneyplus.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-color: #fff;
  }
  .dplay_full{
    height: 45px;
    width: 180px;
  }
  .appletvplus_full{
    background-image: url("../images/apple-tv-plus-full.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-color: #fff;
    height: 70px;
    width: 100px;
  }
  .play-button {
    height: 40px;
    width: 100px;
    background: rgba(0, 0, 0, 0.8);
    background-size: cover;
    border: 0;
    border-radius: 5px;
    color: #fff;
    margin-right: 10px;
  }
  .info-button {
    height: 40px;
    width: 125px;
    background: rgba(0, 0, 0, 0.8);
    background-size: cover;
    border: 0;
    border-radius: 5px;
    color: #fff;
  }
}

.top-10-series {
  position: relative;
  margin-top: -100px;
  @include mobile{
    margin-top: 0;
  }
}

.rank {
  position: absolute;
  z-index: 9;
  font-size: 180px;
  top: 0;
  display: none;
}
.icon-container{
  margin-bottom: 10px;
  padding: 10px;
  // max-width: 200px;
  // background: #fff;
  display: inline-block;
  text-align: center;
  @include allMobiles{
    max-width: 120px;
    padding: 5px;
  }
  .netflix_full{
    height: 60px;
    width: 100px;
    @include allMobiles{
      height: 40px;
     width: 100px;
    }
  }
  .viaplay_full{
    background: url("../images/viaPlay-white.png") 0 0 no-repeat;
    height: 50px;
    width: 180px;
    background-size: 100% 100%;
  }
  .amazonprimevideo_full{
    background: url("../images/prime-white.png") 0 0 no-repeat;
    height: 36px;
    width: 180px;
    background-size: 100% 100%;
  }
  .disneyplus_full{
    background: #fff url("../images/disneyplus.png") 0 0 no-repeat;
  }
  .svtplay_full{
    background-color: #fff;
  }
  .dplay_full{
    background: url("../images/discovery-white.png") 0 0 no-repeat;
    height: 36px;
    width: 180px;
    background-size: 100% 100%;
    @include allMobiles{
      width: 70px;
    }
    
  }
  .appletvplus_full{
    background: url("../images/appleTv-white.png") 0 0 no-repeat;
    height: 60px;
    width: 180px;
    background-size: 100% 100%;
  }
}
.provider-wrapper{
  margin-bottom: 20px;
}
.provider-details {
  > div{
    padding: 30px 0;
  }
  .providerList.providerList.providerList,
  .stream-heading,
  .provider-section {
    display: none;
  }
  .slider-parent{
    padding-left: 50px;
    .slick-prev{
      left:-36px;
    }
  }
  .section-title {
    display: block;
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: 20px;
    // @include allMobiles{
    //   font-size: 16px;
    // }
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.1vw;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.9);
    width: 45%;
    @include tablet{
      font-size: 14px;
    }
  }
  .slick-slide {
    margin: 0 30px;
    @include allMobiles{
      margin: 0 20px;
    }
    @include tablet{
      margin: 0 20px;
    }
  }
  .slick-list {
    margin: 0 -50px 0 -20px;
  }
  .rank {
    position: absolute;
    z-index: 9;
    font-size: 105px;
    top: 25px;
    left: -35px;
    display: none;
    @include allMobiles{
      font-size: 50px;
      top:40px;
      left:-15px
    }
    @include tablet{
      font-size: 70px;
      top:40px;
      left:-25px
    }
  }
  .top-10-movies,
  .top-10-series{
    .rank{
      display: block;
    }
  }
  .back{
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 30%,
        rgba(0, 0, 0, 0)
      ) !important;
  }
  .relative {
    position: relative;
  }
.topSection{
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0));
  padding:10px;
  margin: 0 0 0 -30px;
}
  .slick-slide {
    @include anislow;
    &:hover {
      transform: scale(1.2);
      z-index: 3;
    }
  }
  .slider-parent .slick-slider .slick-slide.slick-center{
    transform: scale(1.2);
    margin-right: 0.3%;
    margin-left: 0.3%;
  }
  .slider-parent {
    @include allMobiles{
     padding-left: 25px;
    }
    @include tablet{
      padding-left: 40px;
     }
  }
}
// .selected-provider{
//   .provider-section{
//     display: none;
//   }
// }
.content-block-medium {
  text-align: left;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 60px;
  @include clearfix;

  @include smallDesktopAndMobile {
    padding: 0;
  }
}
.CookieConsent {
  background: $overlay-background !important;
  > div {
    margin: 0 !important;
  }
}
#rcc-confirm-button {
  @include allMobiles {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
  }
}
#accept-cookies {
  // position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  // background: $overlay-background;
  // display: none;
  .wrap {
    padding-top: 0;

    .content-block {
      margin: 0;
      padding: 20px;
      display: flex;
      @include allMobiles {
        display: block;
      }
      .cookie-text-block {
        width: 100%;
        padding: 0 10px;
        margin-bottom: 20px;
        h3 {
          font-size: 20px;
          margin: 0 0 15px 0;
          border: 0;
        }
        @include allMobiles {
          padding: 0;
        }
      }

      .cookie-accept-block {
        max-width: 100%;
        padding: 0 10px;
        flex: 0 0 30%;
        justify-content: flex-end;
        align-items: flex-end;
        @include allMobiles {
          padding: 0;
        }
        @include allMobiles {
          align-items: flex-start;
        }
        p {
          font-weight: $bold;
          font-size: 20px;
          margin: 0 0 15px 0;
          text-align: right;
          @include allMobiles {
            text-align: left;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.remind-button {
  outline: none;
  border: 0;
  background: transparent url("../images/reminder_unchecked.svg") no-repeat
    center center;

  &:hover,
  &.active {
    background: transparent url("../images/reminder_checked.svg") no-repeat
      center center;
  }
}
.small-card-remind-button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
}
.resetPassword {
  padding-top: 20px;
  h2 {
    margin-bottom: 10px;
    font-weight: 600;
    line-height: normal;
  }
  .row:nth-child(2) {
    justify-content: right;
  }
  .cyan-background {
    max-width: 310px;
    margin: 0;
  }
}

.modal-content {
  background: #000;
  max-width: 320px;
  margin: 0 auto;
  position: relative;
  text-align: left;
  overflow: visible;
  min-height: 400px;
  .streamly_icon {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    margin: auto;
    height: 100px;
    width: 100px;
  }
  label {
    color: #fff;
  }
  .btn {
    display: block;
    // color: #fff;

    width: 100%;
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 10px 25px;
    line-height: 22px;
    border-radius: 25px;
  }
  // .btn-primary{
  //     background-color: #5d1760;
  // border-color: #5d1760;
  // }
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
.form-group {
  margin-bottom: 10px;
  a {
    display: block;
    text-align: center;
  }
}

form label {
  font-size: 18px;
  font-weight: 600;
}

.form-check-input {
  margin-top: 8px;
}

.cyan-background {
  color: #000;
  background-color: #25e6ed;
  border: solid 2px transparent;
}

.content-block-thin {
  text-align: left;
  max-width: 450px;
  margin: 0 auto;

  h1 {
    font-size: 36px;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 24px;
  }
}

.radiobutton-style {
  min-height: 38px;
  width: 100%;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    width: 15px;
    height: 15px;
    @include user-select(none);
    display: inline-block;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid $cyan;
    border-radius: 99999px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 5px 0 0;
  }

  input[type="radio"]:checked + label {
    background: transparent url("../images/radio-checked.svg") no-repeat center
      center;
    background-size: 7px 7px;
  }

  input[type="radio"] + label + label {
    font-size: 18px;
    font-weight: $regular;
    display: inline;
  }
}

button[type="submit"] {
  width: 100%;
  font-size: 18px;
  margin-bottom: 25px;
  margin-top: 25px;
  border-radius: 25px;
}

.genre-link,
.genre-checkbox + label,
.page-size-number {
  @include transition(color 0.15s ease-in-out);
  display: inline-block;
  background-color: transparent;
  font-size: 12px;
  font-weight: $semibold;
  text-transform: uppercase;
  text-align: left;
  padding: 0 10px 0 20px;
  margin-bottom: 10px;
  margin-top: 0;
  min-width: 150px;
  min-height: 26px;
  color: $gray-117;
  line-height: 26px;
  word-spacing: 0;
  cursor: pointer;

  &.active,
  &.selected {
    color: white;
    background: url("../images/check-mark.svg") no-repeat left center;
    text-decoration: none;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.genre-checkbox {
  &:checked + label {
    color: white;
    background: url("../images/check-mark.svg") no-repeat left center;
    text-decoration: none;
  }
}

.modal-backdrop.show {
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(13, 13, 13, 0.8);
  z-index: 20;
  text-align: center;
}

.setting-page {
  h2 {
    font-weight: 300;
    border-bottom: solid 1px #666;
    margin: 0 0 25px 0;
    padding-bottom: 10px;
    text-align: center;
    font-size: 36px;
  }
}

.info-section {
  .leftCol {
    padding: 50px 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 30px;
      line-height: 35px;
      color: #fff;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      color: #fff;
      line-height: 22px;
    }
  }
  img {
    max-width: 550px;
    margin-top: -40px;
  }
}

@include smallDesktopAndMobile {
  .sliderSection {
    margin: 0;
  }
  .main-content {
    .container {
      max-width: 100%;
    }
  }
  .homeSlider {
    margin: 0 0 0 0;
  }
}

.country-select-modal {
  &.show {
    opacity: 1;
  }
}
.select-country {
  .modal-content {
    min-height: 200px;
    h5 {
      padding: 5px 0;
      margin-bottom: 20px;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 3px;
        background: #fff;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        background-color: #ccc;
        padding: 4px;
        color: #222;
        &:hover {
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
