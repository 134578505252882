@import "../../style/mixins.scss";
@import "../../style/constant.scss";

@mixin ani {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@mixin anislow {
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
}

.for-all-provider {
  .stream-listing.stream-listing.stream-listing {
    margin: 0 -0.8474%;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    @include allMobiles {
      padding: 0 10px;
    }
    @include smallDesktopAndMobile() {
      flex-wrap: wrap;
    }

    .relative{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 24%;
      max-width: 24%;
      margin: 0 0.5% 1%;
      background-color: #ebe7e7;
      @include allMobiles {
        flex: 1 0 100%;
        max-width: 100%;
      }
      @include tablet {
        flex: 1 0 calc(50% - 1.9%);
        max-width: calc(50% - 1.9%);
      }
    > div {
      height: 200px;
      max-height: 200px;
      position: relative;
      @include allMobiles {
        flex: 1 0 calc(50% - 1%);
        max-width: calc(50% - 1%);
      }
      @include tablet {
        flex: 1 0 calc(33% - 1%);
        max-width: calc(33% - 1%);
      }
      &.item {
        height: 200px;
        background-position: center center;
        background-size: cover;
        width: 100%;
        @include allMobiles {
          flex: 1 0 100%;
          max-width: 100%;
        }
        @include tablet {
          flex: 1 0 100%;
          max-width: 100%;
        }
        &::before {
          content: " ";
          display: block;
          padding-bottom: 56.986%;
        }

        .uppName {
          @include allMobiles {
            position: static;
          }
        }
      }
      .overlay {
        padding: 0 10px 10px 10px;
        border-radius: 0;
        background-image: none;
        background-color: transparent;
      }
      .back {
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.7) 10%,
          rgba(0, 0, 0, 0)
        );
        background-color: transparent;
        top: 0;
        @include anislow;
        h1,
        p {
          margin: 0;
          color: #fff;
        }
      }

      &:hover {
        .uppName {
          visibility: hidden;
        }
        .back {
          top: 0;
        }
      }
      img {
        max-width: 100%;
        min-height: 100%;
      }
      .user-action {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding:5px;
        justify-content: flex-end;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0));  
        span {
          display: inline-block;
        }
        a{
          margin: 0;
        }
        .addTOlist-icon {
          margin-left: 50px;
        }
      }
      .info-text {
        position: absolute;
        bottom: 10px;
        max-width: 100%;
        padding-right: 10px;
        line-height: 14px;
        h2 {
          font-size: 20px;
          font-weight: 400;
          color: #fff;
          margin: 0 0 10px 0;
          @include mobile() {
            font-size: 16px;
          }
        }
        > span,
        .text-labels span {
          font-size: 12px;
          display: inline-block;
          line-height: 12px;
          text-transform: capitalize;
          padding: 4px;
          &:first-child{
            padding-left: 0;
          }
          @include mobile() {
            font-size: 10px;
            padding: 2px;
          }
        }
        .divider {
          padding: 0 4px;
        }
        .divider:last-child {
          display: none;
        }
        .provider {
          color: #fff;
          padding: 4px;
          font-weight: 700;
        }
      }

      &.inactive {
        .program {
          color: #9b9b9b;
        }
      }
      &.viewAll {
        text-decoration: underline;
        font-weight: 700;
      }
      &.active {
        .program {
          color: #4fd0ec;
          font-weight: 900;
        }
      }
      .play-icon {
        position: absolute;
        top: 5px;
        right: 35px;
        height: 25px;
        width: 25px;
      }
      .top-image-section {
        position: relative;
        height: 165px;
        width: 100%;
        transition-duration: 0.5s;

        &:hover {
          .user-action {
            transition-delay: 0.2s;
            opacity: 1;
          }
        }
        .imageContainer {
          border-radius: 0;
          max-width: 100%;
          min-height: 100%;
          overflow: hidden;
          width: 100%;
          height: 100%;
          // background-size: cover;
          // background-position: center top;
          img {
            max-height: 100%;
          }
        }
        .user-action {
          background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
          box-shadow: 0 2px 2px 0;
          width: 100%;
          // border-radius: 0 0 5px 5px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition-duration: 0.5s;
          opacity: 0;
          padding: 40px 10px 10px 10px;
          display: flex;
          align-items: flex-end;
          z-index: 1;
          .user-action {
            height: 24px;
          }
          .like-icon,
          .dislike-icon,
          .heart-icon,
          .addTOlist-icon2 {
            height: 24px;
            width: 24px;
            margin-right: 10px;
          }
          .heart-icon,
          .addTOlist-icon2 {
            position: absolute;
            bottom: 10px;
            right: 0;
          }
          .addTOlist-icon2 {
            right: 0;
          }
        }
        .play-icon {
          height: 30px;
          width: 30px;
          margin-right: 0;
          right: 5px;
          position: absolute;
          top: 5px;
          z-index: 9;
        }
      }

      .info {
        height: calc(100% - 165px);
        background-color: #ebe7e7;
        padding: 10px;
        position: relative;
        .provider-badge {
          background: #222;
          color: #fff;
          font-size: 10px;
          padding: 2px 4px;
          display: inline-block;
          margin-bottom: 8px;
          text-transform: capitalize;
          letter-spacing: 0.5px;
          margin-left: 5px;
          &:first-child {
            margin-left: 0;
          }
        }
        .genres-badge {
          background: #222;
          color: #fff;
          font-size: 10px;
        }
        span {
          font-size: 12px;
          color: #646464;
          display: inline-block;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
          color: #000;
          margin-bottom: 0;
        }
        a {
          font-size: 11px;
          font-weight: bold;
          position: absolute;
          left: 10px;
          bottom: 10px;
          padding: 3px 10px;
          background: #a0295b;
          color: #fff;
        }
        .badge-info {
          padding-left: 15px;
          display: inline-block;
          text-align: left;
          background: url("../../images/imdb.svg") no-repeat 0 3px;
        }
      }
      .heading-sec {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 14px;
        color: #000;
        margin: 0;
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  }
}
.justify-content-right.justify-content-right.justify-content-right {
  justify-content: right;
}

.live-badge {
  position: absolute;
  z-index: 9;
  background: #a0295b;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  top: 5px;
  left: 5px;
  font-size: 10px;
}
.view-all-btn {
  display: block;
  float: right;
  margin-bottom: 0;
  padding: 5px 20px 5px 10px;
  background: url("/images/arrow-right-thin-white.svg") right 7px no-repeat;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.5px;
  position: relative;
  font-weight: bold;
  background-size: 15px 15px;
  @include allMobiles {
    font-size: 12px;
    background-size: 15px 15px;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    &::before {
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
}
.custom-selectBox {
  border: 1px solid #ccc;
  height: 28px;
  padding: 0 5px;
  max-width: 200px;
  margin: 0 10px;
  > div {
    position: relative;
    padding-top: 3px;
    select {
      -webkit-appearance: none;
      border: 0;
      padding-right: 20px;
      font-size: 12px;
      max-width: 100%;
    }
    &::before {
      content: "";
      height: 9px;
      width: 14px;
      background-image: url(../../images/downArrow.svg);
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-position: right top;
      position: absolute;
      right: 0;
      top: 7px;
    }
  }
}
.topSection {
  display: flex;
  justify-content: space-between;

  .rightSection {
    display: flex;
    justify-content: space-between;
  }
}
.headingWithBorder {
  border-bottom: 2px solid #222;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.genre {
  position: relative;
  select {
    -webkit-appearance: none;
    border: 0;
    padding-right: 20px;
    font-size: 11px;
    max-width: 100%;
  }
  &::before {
    content: "";
    height: 9px;
    width: 14px;
    background-image: url(../../images/downArrow.svg);
    background-size: 14px 9px;
    background-repeat: no-repeat;
    background-position: right top;
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.stream-heading {
  color: #000;
  font-size: 25px;
  line-height: 24px;
  border-bottom: 1px solid #979797;
  padding: 0;
  margin-bottom: 20px;
  font-weight: 300;
  h1 {
    font-weight: 300;
    font-size: 46px;
    @include allMobiles {
      font-size: 24px;
      padding: 0 15px;
    }
    @include tablet {
      font-size: 30px;
    }
    &.with-sub {
      line-height: 0.9em;

      sub {
        bottom: 0;
        font-size: 14px;
        display: block;
        height: 24px;
        line-height: 24px;
        margin-top: 7px;
        a {
          padding-right: 21px;
          background: url(../../images/arrow-teal-right.svg) no-repeat right;
          font-weight: bold;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.headingH2 {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #979797;
  padding: 20px 0 10px 0;
  margin-bottom: 20px;
  font-weight: 900;
}

.sorting {
  // margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    padding: 0 10px;
    border-left: 1px solid #000;

    a {
      color: #000;
      font-size: 18px;
      line-height: 24px;
    }
    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.back-btn {
  font-size: 16px;
  margin: 15px 0;
  display: inline-block;
  font-weight: bold;
}

.large-image {
  max-height: 480px;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    height: 100%;
  }
}

.heading1 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
}

.blog-text {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
  h2 {
    font-size: 22px;
    line-height: 26px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    color: #6d6d6d;
    font-size: 14px;
    line-height: 18px;
    //h2 {
    //  color: #6D6D6D;
    //  font-size: 14px;
    //  line-height: 18px;
    //  font-weight: normal;
    //  margin-bottom: 0;
    //}
  }
}

.program-details {
  max-width: 100%;
  margin: 0 auto;
  .sliderSection {
    margin: 0;
  }

  h2 {
    color: #000000;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .meta-info {
    color: #000000;
    font-size: 14px;
  }
  video {
    max-width: 100% !important;
    max-height: 480px !important;
    margin: 20px 0;
  }
  .homeSlider {
    margin-bottom: 60px;
  }
}

.blog-ads {
  margin: 20px 0;
}

@include tablet {
  .program-listing-page,
  .program-details {
    padding: 0 15px;
  }
  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      // margin: 0 5px 10px 5px;
    }
  }
}

@include allMobiles {
  .program-listing-page {
    padding: 0 5px;
  }

  .program-details {
    padding: 0 15px;
  }

  .blog-listing.blog-listing.blog-listing {
    li {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      // margin: 0 5px 10px 5px;
      background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
      position: relative;
      .image {
        height: auto;
        min-height: 50px;
      }
      .info {
        height: auto;
        border: 0;
        position: static;
      }
    }
  }
  .topSection {
    flex-direction: column;
    margin-bottom: 20px;
    .sorting {
      justify-content: center;
      span {
        border: 0;
        margin-bottom: 10px;
        a {
          font-size: 14px;
          line-height: 20px;
          display: inline-blocks;
        }
      }
    }
    .rightSection {
      justify-content: center;
    }
  }
}

.stream-listing {
  .item {
    .free-overlay {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 10;

      .free-text {
        color: #f7f7f7;
        font-size: 12px;
        //font-weight: $bold;
        text-transform: uppercase;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      }
    }

    .live-overlay {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 12px;
      border: 2px solid white;
      border-radius: 50px;
      padding: 8px 10px;
      box-shadow: inset 1px 1px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      color: red;
      z-index: 15;
      > span {
        font-weight: bold;
        color: white;

        &.live-text {
          display: none;
        }
      }
    }

    &.is-live {
      .live-overlay {
        box-shadow: none;
        background: $live-red;
        border: none;
        top: 22px;
        left: 22px;

        > span {
          padding-left: 18px;

          &.live-text {
            display: inline;
          }

          &.time-text {
            display: none;
          }
        }

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          top: 9px;
          background-color: white;
          border-radius: 10px;
          position: absolute;
        }
      }
    }

    .percent-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;

      > .bar {
        background-color: $live-red;
        height: 2px;
      }
    }
  }
}

.gotoMenu {
  display: flex;
  justify-content: space-between;
  h2 {
    margin-bottom: 0;
    font-size: 24px;
    text-transform: capitalize;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      margin: 0 5px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: inline-block;
        padding: 5px 15px;
        color: #fff;
        background: #30609e;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
      }
    }
  }
}
.stream-listing-page {
  .provider-section {
    margin: 0;
    padding-top: 10px;
    
    // &.provider-selected{
    //   position: fixed;
    //   top:40px;
    //   z-index: 99;
    //   background: #000;
    //   width: 100%;
    // }
    .container{
      margin: 0;
    }
    @include allMobiles{
      top:45px
    }
  }
  .headingWithBorder{
    .amazonprimevideo_full{
      background: url("../../images/provider/AmazonPrime.png") 0 0 no-repeat;
      height: 66px;
      width: 90px;
      @include allMobiles {
        height: 40px;
        width: 50px;
        background-size: 100% 100%;
      }
    }
    .disneyplus_full{
      background-image: url("../../images/provider/disneyplus.png");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-color: #fff;
      height: 55px;
      width: 70px;
      @include allMobiles {
        height: 35px;
        width: 50px;
      }
    }
    .svtplay_full{
      background-image: url("../../images/svt-play-full.svg");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-color: #fff;
      height: 50px;
      width: 75px;
    }
    .viaplay_full{
      background-image: url("../../images/viaplay-full.svg");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-color: #fff;
      height: 40px;
      width: 55px;
    }
    .dplay_full{
      height: 35px;
      width: 120px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-color: #fff;
    }
    .appletvplus_full{
      background-image: url("../../images/apple-tv-plus-full.svg");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-color: #fff;
      height: 50px;
      width: 75px;
    }
    .provider-icon{
      margin-right: 10px;
    }
  }
}

.even-columns {
  @include columns(3 auto);
  max-width: 450px;
  @include allMobiles {
    @include columns(2 auto);
    max-width: 300px;
  }
}

/* Content inside menus - checkbox/labels or links */
.genre-link,
.genre-checkbox + label,
.page-size-number {
  @include transition(color 0.15s ease-in-out);
  display: inline-block;
  background-color: transparent;
  font-size: 12px;
  font-weight: $semibold;
  text-transform: uppercase;
  text-align: left;
  padding: 0 10px 0 20px;
  margin-bottom: 10px;
  margin-top: 0;
  min-width: 150px;
  min-height: 26px;
  color: $gray-117;
  line-height: 26px;
  word-spacing: 0;
  cursor: pointer;

  &.active,
  &.selected {
    color: white;
    background: url("../../images/check-mark.svg") no-repeat left center;
    text-decoration: none;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.genre-checkbox {
  display: none;
  &:checked + label {
    color: white;
    background: url("../../images/check-mark.svg") no-repeat left center;
    text-decoration: none;
  }
}
.block-filter {
  // @include clearfix;
  position: relative;
  //min-height: 70px;
  text-align: center;
  margin-bottom: 15px;

  .sort-order {
    list-style-type: none;
    margin: 0 0 10px 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      a {
        font-size: 14px;
        font-weight: $bold;
        color: $gray-117;
        text-decoration: none;

        &.selected {
          color: white;
        }

        &:hover {
          color: white;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  /* Drop down menu buttons */
  .select-day-menu-button,
  .select-genre-menu-button,
  .select-source-menu-button,
  .select-page-size-menu-button {
    @include appearance(none);
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-right: 10px;
    text-transform: capitalize;
    color: #fff;
    @include allMobiles {
      font-size: 14px;
    }
    // Arrow after
    &::after {
      background: transparent url("../../images/arrow-down-white.svg") no-repeat
        right center;
      content: "";
      display: inline-block;
      width: 13px;
      height: 8px;
      margin-bottom: 3px;
      margin: 2px 10px 1px 5px;
    }
    // Extended arrow
    &.extended {
      &::after {
        background: transparent url("../../images/arrow-up-white.svg") no-repeat
          right center;
      }
    }
    // Focus state
    &:focus {
      outline: none;
      background-color: $gray-34;
      border-radius: 4px;
    }

    &.filter-active {
    }
  }
}

#select-source-button {
  @include transition(background-color 0.15s ease-in-out);
  margin-top: 20px;
  &.disabled {
    background-color: $gray-117;
  }
}

/* Drop down menus  */
.select-drop-down {
  display: none;
  position: absolute !important;
  right: 0px;
  z-index: 16;
  background: $genre-menu-background;
  text-align: center;
  border: solid 1px $gray-117;
  padding: 15px 15px 30px 15px !important;
  width: auto;
  top: 60px;
  .not-logged-in {
    .feedback-login-button {
      @include appearance(none);
      border: 0;
      background: transparent;
    }
  }
  @include allMobiles {
    width: 100%;
    padding: 20px 15px 20px 15px;
    top: 60px;
  }
}

@include tablet {
  .stream-listing-page {
    padding: 0 5px;
  }
  .stream-listing.stream-listing.stream-listing {
    li {
      flex-basis: calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      // margin: 0 5px 10px 5px;
    }
  }
}

@include allMobiles {
  .stream-listing-page {
    padding: 0;
    .headingWithBorder {
      margin-bottom: 50px;
      font-size: 20px;
      padding-left: 10px;
      padding-bottom:10px;
      position: relative;
      z-index: 9;
      background: #000;
    }
  }

  .stream-listing.stream-listing.stream-listing {
    li {
      flex-basis: 100%;
      max-width: 100%;
      // margin: 0 5px 10px 5px;
      background-color: #ebe7e7;
      border-bottom: 2px solid #979797;
      position: relative;
      .image {
        height: auto;
        min-height: 50px;
      }
      .info {
        height: auto;
        border: 0;
        position: static;
      }
    }
  }
}

@include respond-below(xs) {
  .gotoMenu {
    h2 {
      display: none;
    }
    ul {
      li {
        a {
          padding: 5px;
          font-size: 12px;
          min-width: 60px;
          text-align: center;
        }
      }
    }
  }
}
