@import '../style/constant.scss';
@import '../style/mixins.scss';

.modal-header{
    border: 0;
    padding:2rem
}

.modal-body{
    padding:0 20px 20px 20px;
}
.form-control{
    height: calc(1.5em + .95rem + 2px);
}

.form-group{
    margin-bottom: 2rem;
}
.modal-header .close {
    margin: -2rem -2rem -1rem auto;
}
.modal-footer{
    border: 0;
    padding:0 2rem 2rem 2rem
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: $borderColor;
    outline: 0;
    box-shadow: none;
}

.btn-primary{
    background-color:$borderColor;
    border: 0;
    box-shadow: none;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color:#25e6ed;
    border-color:transparent;
}
.btn-primary.focus,
.btn-primary:focus{
    box-shadow: none;
}
.modal-footer{
    .btn-primary{
        margin: 0 auto;
        min-width: 120px;
        line-height: 2;
        border-radius: 2rem;
        text-transform: uppercase;
    }
}

.form{
    .row{
        margin-bottom: 2rem;
    }
}

.form span.error{
    color: red;
    font-size: 13px;
}

.register-form{
    margin: 0 auto;
    float: none;
    max-width: 850px;
    padding-top: 50px;
    h2{
        font-weight: 300;
        border-bottom: solid 1px #666;
        margin: 0 0 25px 0;
        padding-bottom: 10px
    }
    .row{
        margin-left:0;
        margin-right:0;
    }
    .btn-primary{
        width: 100%;
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 10px 25px;
    line-height: 22px;
    }

    .two-column{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

}
@include allMobiles {
    .register-form{
        .social-column{
            margin-bottom: 30px;
        }
    .two-column{
        display: block;
        
    }
}
}