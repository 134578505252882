@import '../style/constant';
@import '../style/mixins';

.homeSlider{
    .item{
        height: 520px;
    }
}



.item{
    position: relative;
    overflow: hidden;
    
    .image{
        max-width: 100%;
        min-height: 100%;
        border-radius: 10px;
    }
    .parent-bg{
        background-repeat: no-repeat;
        border: 0;
        margin: 0;
        max-width: 100%;
        min-height: 100%;
        display: block;
        position: absolute;
        width: 100%;
        background-position: center 10%;
        background-size: cover;
    }
    .overlay{
         position: absolute;
         top:0;
         left:0;
         height: 100%;
         width: 60%;
         z-index: 9;
         border-radius: 8px;
         display: flex;
         flex-direction: column;
         justify-content: start;
         padding: 100px 100px 0 40px;
         content: '';
        //  background-image: radial-gradient(ellipse closest-side,transparent 70%,rgba(0,0,0,.8));
        //  background-position: center 100%;
        //  background-size: 125%;
        background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.7) 10%,
            rgba(0, 0, 0, 0)
          );
          background-color: transparent;
         display: block;
         position: absolute;
         width: 100%;
         height: 100%;
         h3{
            color:#fff;
            font-size: 50px;
            margin-bottom: 0;
            line-height: 65px;
            border:0;
            text-align: left;
         }
         span{
             font-size: 20px;
             color: #fff;
             text-align: left;
             display: block;
         }
    }
}
.slick-slide{
    padding: 0 !important;
}

.controls{
    z-index: 99;
    position: absolute;
}
.controls{
    position: absolute;
    left:0;
    right: 0;
    margin: auto;
    top:0;
    width: 100%;
    z-index: 9;
}

.slick-dots li button:before{
    color: #fff;
    height: 15px;
    width: 15px;
    font-size: 10px;
    line-height: 15px;
}
.slick-dots li.slick-active button:before{
    color: #fff;
}
.slick-dots li button{
    padding: 3px;
    height: 15px;
    width: 15px;
}

.slick-prev{
    left:0;
    z-index: 9;
}
.slick-next{
    right:0;
    z-index: 9;
}
.slick-prev:before{
    content: "";
    background: url('../images/arrow-left-thin-white.svg') 0 0 no-repeat;
    height: 80px;
    width: 80px;
    position: absolute;
}
.slick-next:before{
    content: "";
    background: url('../images/arrow-right-thin-white.svg') 0 0 no-repeat;
    height: 80px;
    width: 80px;
    position: absolute;
    right: -40px;
}



.stream-listing-page{
    overflow: hidden;
}

@include respond-below(md){
    .stream-listing-page{
        padding: 0 !important;
    }
   .homeSlider{
       .item {
            .overlay {
                h3{
                    font-size:40px;
                    line-height: 45px;
                }
                span{
                    margin-bottom: 0 !important;
                }
            }
        }
   } 
   .headingWithBorder{
       font-size: 22px;
   }
   .program-list{
       
    .item {
        .overlay{
            padding: 5px;
            .user-action{
                bottom:0;
                .addTOlist-icon2{
                    height: 28px;
                    width: 28px;
                    @include allMobiles{
                        height: 20px;
                        width: 20px;
                    }
                    @include tablet{
                        height: 20px;
                        width: 20px;
                    }
                }
            }
            .info-text {
                h2{
                    font-weight: 700;
                    font-size: 16px;
                    margin: 0 0 5px 0;
                    user-select: none;
                }
                >span{
                    margin-bottom: 0 !important;
                }
            }
        }
    }
   } 
   .slick-prev:before,
   .slick-next:before{
       height: 30px;
       width: 30px;
       background-size: contain;
   }
   .slick-next:before{
       right: 0;
   }
}
@include allMobiles{
    .homeSlider {
        .item {
            .overlay {
                h3{
                    font-size: 25px;
                    line-height: 30px;
                    padding-bottom: 0;
                }
                .info{
                    padding: 0 10px;
                }
        }
        
    }
    .slick-slide .item{
        height: 250px;
        min-height: 250px;
        .overlay{
            padding: 30px 15px 15px 15px;
            span{
                font-size: 15px;
            }
        }
    }
    .slick-next, .slick-prev{
        top:45%;
    }
    .slick-next:before{
        right: -10px;
    }
    .slick-prev:before{
        left:0;
    }
}
}