@import "../../style/constant";
@import "../../style/mixins";

.header {
  background: rgba(0,0,0,0.7);
  padding: 0 30px;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 60px;

  h1 {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .padding0 {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 60px;
    &:last-child {
      justify-content: flex-end;
    }
  }
  @include tablet{
    height: 60px;
    .padding0{
      height: 60px;
    }
  }
  @include allMobiles{
    height: 60px;
    top:-1px;
    background: rgba(0,0,0,1);
    .padding0{
      height: 60px;
    }
  }
}
.logo {
  padding: 0 10px 0 10px;
  height: 80px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    vertical-align: middle;
    height: 22px;
  }
}
.header-login {
  display: block;
  position: relative;
  // right: 80px;
  // margin-top: 10px;
  background: transparent;
  border: 0;
  outline: none;
  color: $gray-153;
  white-space: nowrap;
  // vertical-align: middle;
  float: left;
  // top: 50%;
  // @include transform(translateY(-50%));
}

.hamburgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.hamburgerIcon {
  height: 24px;
  width: 24px;
  display: inline-block;
  background: url(../../images/hamburger.svg) no-repeat 0 0;
  background-size: 100% 100%;
  margin-right: 7px;
  cursor: pointer;
}

.navbar {
  padding: 0;
  // max-width: 620px;
  margin: 0 auto;
  justify-content: flex-start;
}
header nav {
  border-radius: 5px;
  .film-selected {
    background: #a0295b;
    color: white;
  }

  .series-selected {
    background: #30609e;
    color: white;
  }

  .sport-selected {
    background: #3b8433;
    color: white;
  }

  a {
    color: #aaa;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 4px;
    position: relative;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0 4px;
    &:hover {
      color: #25e6ed;
      text-decoration: none;
    }
    &:first-child {
      margin-left: 15px;
    }
    &.selected{
      background-color:#a0295b;
      color: #fff;
      font-weight: bold;
    }

    @include smallDesktopAndLargeDestop() {
      padding: 5px 8px;
      margin: 0 2px;
      font-size: 16px;
    }
    @include smallDesktopAndMobile() {
      padding: 5px 4px;
      margin: 0 2px;
      font-size: 16px;
    }
  }

  .active {
    color: #25e6ed;
    text-decoration: none;
    &:hover {
      color: #25e6ed;
    }
  }

  .user-link {
    position: relative;

    a {
      color: #fff;
      background: #222;
      box-shadow: none;
      padding: 7px 0.5rem;
      svg {
        margin: -4px 10px 0 0;
      }

      &:hover {
        color: #fff;
        text-decoration: none;
        background: #2e2e2e;
      }
    }

    .dropdown {
      display: none;
      position: absolute;
      top: 37px;
      z-index: 9;
      a {
        color: #222;
        display: inline-block;
        padding: 5px 0.7rem;
        border-radius: 4px;
        position: relative;
        text-transform: uppercase;
        box-shadow: 0 2px 4px 4px #ccc;
        background: #fff;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
      }
    }
    &:hover {
      .dropdown {
        display: block;
        a {
          color: #222;
          background: #fff;
        }
      }
    }
  }
}
.search {
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 55px;

  .icon,
  .btn {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: inline-block;
    background: url(../../images/search.svg) no-repeat 0 0;
    background-size: 100% 100%;
    // margin-right: 10px;
    cursor: pointer;
  }

  .form-control {
    width: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: 1s;
    color: white;
  }
}
.autocomplete-menu {
  position: absolute;
  right: 0;
  padding-left: 0;
  list-style: none;
  top: 43px;

  margin: 0;

  width: 225px;
  max-height: 260px;
  overflow-y: auto;
  list-style-type: none;
  background: $gray-41;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  li {
    list-style: none;
    margin: 0;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: $bold;
    line-height: 20px;
    display: block;
    border-radius: 3px;
    min-height: 40px;
    margin: 0;
    cursor: pointer;
    padding: 10px 15px;

    &:hover {
      background: $gray-32;
    }
    &:first-child,
    &:last-child {
      padding-top: 10px;
    }
  }
}
.search-active {
  width: 100%;
  max-width: 240px;
  min-width: 225px;
  position: absolute;
  top: 15px;
  //     .autocomplete-menu{
  //     padding: 10px;
  // }
  .form-inline {
    width: 100%;
  }
  .form-control {
    margin: 0;
    height: 40px;
  }
  .btn {
    visibility: hidden;
    z-index: 1;
  }
  .form-control {
    padding: 0 0.5rem;
    border: 1px solid #ccc;
    background: #292929;
    width: 100%;
    border: 0;
    font-size: 22px;
  }
  .form-control,
  .search {
    width: 100%;
  }
  .search .icon {
    background: url(../../images/search-white.svg) no-repeat 0 0;
    background-size: 100% 100%;
  }
}

.header {
  .select-source-menu-button {
    background: rgb(80, 79, 79);
    border: 1px solid#25e6ed;
    min-width: 90px;
    height: 32px;
    position: relative;
    text-align: left;
    padding: 5px;
    margin: 5px 5px 0 0;
    color: #fff;
    box-sizing: border-box;
    line-height: 22px;
    &::before {
        position: absolute;
      content: "";
      height:10px;
      width: 10px;
      border-style: solid;
      border-color: white;
      border-width: 0px 1px 1px 0px;
      transform: rotate(45deg);
      transition: border-width 150ms ease-in-out;
      right: 10px;
      top:8px;
    }
  }
}

@include respond-above(sm) {
}
@include respond-above(md) {
  .logo {
    margin-bottom: 0;
  }
}

@include respond-below(md) {
  // .navbar{
  //     justify-content:unset;
  // }
  .container {
    max-width: 100%;
  }
  .header {
    .row {
      justify-content: space-between;
      .padding0 {
        position: static;
      }
    }
    .navbar {
      a {
        padding: 5px;
        margin: 0 4px;
        font-size: 12px;
      }
    }
    .dropdown.dropdown.dropdown {
      right: 0;
      top: 35px;
      &::before {
        left: auto;
        right: 10px;
      }
    }
    h1 {
      position: absolute;
    //   margin: auto;
      left: 60px;
      right: 0;
      width: 160px;
    }
    .header-login {
      display: none;
    }
  }
  .mobile-top-nav {
    .navbar {
      max-width: 100%;
      flex-direction: column;
      a {
        width: 100%;
        text-align: left;
      }
    }
  }
}
@include respond-below(sm) {
  .logo {
    margin-top: 0;
  }

  .search {
    margin: 0;
  }
  .navbar {
    margin-bottom: 15px;
    a {
      min-width: 50px;
      text-align: center;
      font-size: 11px;
      &.user-link {
        min-width: 30px;
      }
    }
  }
}

@include respond-below(xs) {
  header {
    .col-xs-12.col-sm-12.col-md-12.col-lg-6 {
      position: static;
    }
    .navbar {
      position: static;
      margin-bottom: 15px;
      a {
        padding: 5px 7px;
        margin: 0 2px;
        font-size: 10px;
      }
      .user-link {
        svg {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
    .row {
      .mobileView.mobileView {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 50%;
        min-width: 240px;
        .search .btn {
          right: 20px;
        }
        .search-active .btn {
          right: 20px;
        }
        .search .icon {
          right: 20px;
        }
      }
    }
    &.header {
        .select-source-menu-button{
            min-width: 70px;
        }
    }
  }
}
