@import '../../../style/constant';
@import '../../../style/mixins';
.news-list {

	//position: relative;
	//text-align: center;
    .news-item {
	    border-bottom: solid 1px rgba(68,68,68,1.0);
        margin-bottom: 15px;
        padding-bottom: 15px;
        position: relative;

    	// @include clearfix;

        a {
            display:block;
            color: white;
            font-weight: $semibold;

            &:hover {
                text-decoration:none;
            }
        }

        p {
            font-size: 14px;
            @include largeDesktop (){
                font-size: 16px;
                font-weight: 400;
            }
        }

        .news-thumb {
            float: left;
            width: 95px;
            margin-right: 15px;

            img {
                max-width: 100%;
                width:95px;
                height: 70px;
                background: url(../../../images/fallback-images/card-95x70.jpg) no-repeat;
            }
        }

        .news-description {
            width: 100%;
        }

        .time {
            color: $gray-117;
        }   

    }

    .provider-logo {
        max-width: 70px;
        max-height: 30px;
    }

	
}





