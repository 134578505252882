@import '../../style/constant';
@import '../../style/mixins';
.footer{
    color: #fff;
    margin-top: 1rem;
    // border-top: 2px solid #575757;
    padding: 7px 0;
    font-size: 12px;
    text-align: center;
    nav{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0;
                padding: 0;
                display: inline-block;
                border-left: 1px solid #645959;
                &:first-child{
                    border: 0 ;
                }
            }
        }
        a{
            display: inline-block;
            margin: 0 0.5rem;
            color: #575757;
            padding: 0.05rem 1rem;
            position: relative;
            line-height: 14px;
            border-left: 1px solid #645959;
            // &:hover, .active{
            //     @include hoverAnimation
            // }
            &:first-child{
                border: 0;
                margin-left:0;
            }
        }
    }
    p{
        margin: 0;
        text-align: right;
        color:#575757;
        line-height: 28px;
    }
}
.sponsorBanner{
    position: relative; 
    background-color: #E8ECF1; 
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 40px;
    max-width: 1024px;

    .title{
      color:#5D6164; 
      font-size:14px; 
      text-transform:uppercase; 
      background-color: #D1D3D6; 
      padding: 4px; 
      position: absolute; 
      left:0; 
      top:-10px; 
      font-weight: bold;
    }
    ul{
      list-style: none; 
      margin: 0; 
      padding: 0; 
      text-align:center;
      li{
        display: inline-block; 
        padding: 10px; 
        margin: 0 10px;
        width: calc(25% - 20px);
        img{
            max-width: 100%;
        }
      }
    }
  }
small {
    color: $gray-117;
    font-size: 12px;
    font-weigth: $regular;
}

.logo-container {

    .footer-logo {
        display: block;
        width: 120px;
        height: 86px;
        margin: 0 auto 10px auto;
    }
}

.link-container {
        margin: 10px auto 10px auto;
}


.footer-menu{
    color: #fff;
    margin-top: 1rem;
    border-top: 2px solid #575757;
    padding: 15px;
    font-size: 12px;
    background-color: #1c1d1d;
    margin: 0 -40px;
    .top{
        text-align: left;
    }
    .justify{
        justify-content: center;
        align-items: center;
        .col-sm-9{
            padding: 0;
        }
    }
    h2{
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 10px;
        color: #fff;
    }
    p{
        line-height: 1.4;
        letter-spacing: -.1px;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color:#c4d0e6;
        @include allMobiles{
            text-align: left !important;
        }
    }
    .col-sm-4{
        padding: 0 0;
        text-align: left;
    }
    nav{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0;
                padding: 0;
                display: inline-block;
                border-left: 1px solid white;
                &:first-child{
                    border: 0 ;
                }
            }
        }
        a{
            display: inline-block;
            color: #c4d0e6;
            padding: 0.6rem;
            position: relative;
            font-size: 12px;
            line-height: 0;
            height: 16px;
            border-left:0;
            background: transparent;
            // &:hover, .active{
            //     @include hoverAnimation
            // }
            &:first-child{
                border: 0;
                margin-left:0;
            }
        }
    }
    p{
        margin: 0 0 15px 0;
    }
    .col-sm-4{
        padding-right: 30px;
    }
    h3{
        color:#f2f2f2;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        a{
            color:#f2f2f2;
            display:inline-block;
            line-height: 20px;
        }
    }
    .row{
        margin: 0;
    }
    .copyright{
        padding-top: 20px;
        border-top: 1px solid #fff;
        text-align: center;
    }
}


@include allMobiles{
    .footer{
        nav{
            a{
                margin: 0;
                padding: 0 8px;
            }
        }
        p{
            padding-top: 10px;
            text-align: center;
        }
    }
    .sponsorBanner{
        padding: 20px 10px;
        ul {
            li{
                width: calc(100% - 20px);
                max-width: calc(100% - 20px);
                margin:0;
                img{
                    max-width: 100%;
                }
            }
        }
    }
}
