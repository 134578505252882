$prefix-for-webkit:    true !default;
$prefix-for-mozilla:   true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera:     true !default;
$prefix-for-spec:      true !default;
// Element variables
$sidebar-width: 300px;
$header-height: 80px;
// Gray colors
$gray-21: rgba(21, 21, 21, 1.0);
$gray-32: rgba(32, 32, 32, 1.0);
$gray-34: rgba(34, 34, 34, 1.0);
$gray-37: rgba(37, 37, 37, 1.0);
$gray-41: rgba(41, 41, 41, 1.0);
$gray-55: rgba(55, 55, 55, 1.0);
$gray-68: rgba(68, 68, 68, 1.0);
$gray-78: rgba(78, 78, 78, 1.0);
$gray-106: rgba(106, 106, 106, 1.0);
$gray-117: rgba(117, 117, 117, 1.0);
$gray-139: rgba(139, 139, 139, 1.0);
$gray-153: rgba(153, 153, 153, 1.0);
$gray-170: rgba(170, 170, 170, 1.0);
$gray-183: rgba(183, 183, 183, 1.0);

$susy-media: () !default;
$susy-media-fallback: false !default;

$_susy-media-context: ();



$header-background: black;//rgba(13,13,13,0.9);

$overlay-background: rgba(13,13,13,0.8);

$genre-menu-background: rgba(0,0,0,0.85);

$facebook-blue: rgba(58, 85, 145, 1.0);

$google-red: rgba(222, 67, 48, 1.0);

$twitter-blue: rgba(41, 168, 225, 1.0);

$email-green: rgba(127, 191, 63, 1.0);

$invalid-background: rgba(255,164,164,1.0);
$invalid-message: rgba(235,54,54, 1.0);

$live-red: #eb3636;
// Font weights in text
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;
$extrabold: 800;


$susy: (
	columns: 12,
	container: 1600px,
	gutters: .25
);
// Cyan colors
$cyan: rgba(37, 230, 237, 1.0);
$cyan-disabled: rgba(7, 46, 47, 1.0);
$cyan-darker: rgba(0, 158, 164, 1.0);

$bp-large: 1090px;

$marginbtm: 20px;
$primaryColor:#000;
$secondaryCloar:#5d1760;
$fontColor:#fff;
$borderColor:#222;
$headingColor:#fff;
$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeLarge: 18px;

$lightGreyBg: #ccc;
$smallHeading:#fff;
  
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

$breakpoint: map-get($breakpoints, sm);
p{
    color: $fontColor;
}
// Breakpoints
// Before 321px is default styling = mobile first
$bp-smallest: 321px;
$bp-small: 450px;
$bp-medium-small: 570px;
$bp-medium: 768px;
$bp-large: 1090px;
$bp-largest: 1200px;
$breakpoint-no-query-fallbacks: true;

body, html{
    color: #fff;
    font-size:1rem ;
    font-style: normal;
    font-weight: normal;
    line-height: 1.63;
}


.padding0{
    padding:0;
}
.paddingRight0{
    padding-right: 0;
}
.paddingLeft0{
    padding-left: 0;
}
.noPadding.noPadding.noPadding{
    padding: 0;
}
.grey-gradient{
    background: rgb(125,126,125); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(125,126,125,1) 0%, rgba(76,76,76,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(125,126,125,1) 0%,rgba(76,76,76,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#4c4c4c',GradientType=0 ); /* IE6-9 */
}

h1,h2,h3,h4,h5{
    color: $headingColor;
    font-weight: 900;
}

h3{
    color: $smallHeading;
    font-size: 1.5rem;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #747474;
}

.notes{
    padding:2rem;
    font-size: 16px;
    background: rgba(255, 153, 90, 0.5);
    color:#000;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 800px;
    a{
        color:#3717aa;
        text-decoration: underline;
    }
}
.modal-header{
    .close{
    background:url(../images/close.svg) no-repeat 5px 4px;
   
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0;
    opacity: 1;
    overflow: hidden;
    text-indent: -9999px;
    border:0;
    &:focus{
        border:0;
        outline: 0;
    }
    }
}

.toggle-btn{
    color: #fff;
    font-size: 0.8rem;
    margin: 0 5px;
    display: inline-block;
    cursor: pointer;
    border-radius: 6px;
    background: #212121;
    padding:2px 4px;
    position: relative;
    min-height: 16px;
    &::before{
        content: "";
        width: 40%;
        background: rgba(93, 93, 93, 1);
        position: absolute;
        height: 100%;
        top:0;
        right: 0;
        border-radius: 6px;
    }
    &.off{
        background: rgba(93, 93, 93, 1);
        color: #222;
        text-align: center;
    }
    &.off::before{
        content: "";
        width: 40%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        height: 100%;
        top:0;
        left: 0;
        border-radius: 6px; 
    }

}


.print-icon{
    float:right;
    font-size: 16px;
    cursor: pointer;
}
