@import '../style/constant.scss';
@import '../style/mixins.scss';

#sidebar-nav-button {
	background: transparent url('../images/mobile-top-nav-icon.png') no-repeat left top;
	border: 0;
	width: 33px;
	height: 22px;
	// position: absolute;
	// left: 20px;
	// Vertical centering
	// margin-top: 15px;
	// @include transform(translateY(-50%));
}

.main-content-wrap {
	position: relative;
	width: 100%;
	overflow: auto;
	z-index: 1;
	background: black;
	left: 0px;
	-webkit-overflow-scrolling: touch;
	@include transition(left 0.35s ease);
}

.sidebar {
	position: fixed;
	overflow: auto;
	max-height: 100%;
	height: calc(100% - 60px);
	width: $sidebar-width;
	z-index: 9;
	background-color: $gray-21;
	margin-top:0;
	bottom:0;
	padding-bottom:70px;
	// -webkit-overflow-scrolling: touch;
	// Sidebar is hidden to the left
	left: -300px;
	@include transition(left 0.35s ease);

	.text {
		padding: 0 20px;
		color: white;

		p, li {
			font-size: 16px;
		}

		ul {
			padding-left: 18px;
		}
	}
}

.sidebar-expanded {
	// Sidebar expanded
	left: 0;
	display: block;
	// Main content is offset when sidebar expands
	& ~ .main-content {
		left: $sidebar-width;
	}
	.sidebar-nav{
		display: block;
	}
}

.user-info-button {
	width: 100%;
	border: none;
	background: $gray-41 url('../images/arrow-down-white.svg') no-repeat right 20px center;
	max-height: 70px;
	padding: 15px 20px;
	@include clearfix;

	.user-icon {
		float: left;
		width: 40px;
		height: 40px;
	}

	.user-info {
		float: left;
		margin: 0 0 0 15px;
		text-align: left;
		max-width: 180px;

		.user-name {
			display: block;
			font-size: 14px;
			font-weight: $regular;
			color: $gray-117;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.current-profile {
			display: block;
			font-size: 18px;
			font-weight: $bold;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
            color: #fff;
		}
	}
}

.profile-menu {
	background: $gray-41;
	width: 260px;
	margin: 20px;
	padding: 0;
	display: none;
	border-radius: 5px;
	position: absolute;
	z-index: 1;

	&:after {
		top: -9px;
		right: 20px;
		border-width: 0 9px 9px;
		content: "";
		position: absolute;
		border-style: solid;
		border-color: $gray-41 transparent;
		display: block;
		width: 0;
	}


	.profile-menu-panel {
		list-style-type: none;
		margin: 10px;
		padding: 0;

		li {

			a {
				font-size: 18px;
				font-weight: $bold;
				text-decoration: none;
				color: white;
				line-height: 40px;
				display: block;
				border-radius: 3px;
				height: 60px;
				margin: 0;
				padding: 10px 15px;

				.avatar {
					height: 40px;
					width: 40px;
					float: left;
					margin-right: 10px;
				}

				&:hover {
					background: $gray-32;
				}
			}
		}
	}

	.new-profile-panel {
		margin: 10px;
		padding: 0;
		display: none;

		h3 {
			font-size: 18px;
			font-weight: $bold;
			margin: 0 0 20px 0;
		}

		#new-profile-form {
			padding: 10px 15px;
			@include clearfix;

			input[type="text"] {
				margin-bottom: 15px;
			}

			.checkbox-style {
				margin-bottom: 10px;
			}

			button {
				padding: 10px 18px;
			}

			#new-profile-cancel {
				float: left;
			}

			#new-profile-continue {
				float: right;
			}
		}
	}
}

.sidebar-nav {
	margin: 20px 0;

	a {
		color: $gray-170;
		font-size: 18px;
		display: block;
		text-decoration: none;
		padding: 10px 0 10px 55px;
		background-size: 17px 17px;
		background-repeat: no-repeat;
		background-position: 20px center;

		&:hover {
			color: white;
			background-color: $gray-32;
		}

		&.home {
			background-image: url('../images/home.svg');

			&:hover {
				background-image: url('../images/home-hl.svg');
			}
		}

		&.settings {
			background-image: url('../images/settings.svg');

			&:hover {
				background-image: url('../images/settings-hl.svg');
			}
		}

		&.profile {
			background-image: url('../images/user-profile.svg');

			&:hover {
				background-image: url('../images/user-profile-hl.svg');
			}
		}

		&.list {
			background-image: url('../images/list.svg');

			&:hover {
				background-image: url('../images/list-hl.svg');
			}
		}

		&.list-public {
			background-image: url('../images/public_lists.svg');

			&:hover {
				background-image: url('../images/public_lists_white.svg');
			}
		}

		&.help {
			background-image: url('../images/help.svg');

			&:hover {
				background-image: url('../images/help-hl.svg');
			}
		}

		&.login {
			background-image: url('../images/logout.svg');
			float: none;
			display: block;
			&:hover {
				background-image: url('../images/logout-hl.svg');
			}
		}

		&.logout {
			background-image: url('../images/logout.svg');

			&:hover {
				background-image: url('../images/logout-hl.svg');
			}
		}

		&.favourites {
			background-image: url('../images/nav-favorites-icon.svg');

			&:hover {
				background-image: url('../images/nav-favorites-hl-icon.svg');
			}
		}

		&.suggestion {
			background-image: url('../images/contact.svg');

			&:hover {
				background-image: url('../images/contact_white.svg');
			}
		}
		/*&.star { background-image: url('../img/icons/star.svg');
			&:hover {
				background-image: url('../img/icons/star-hl.svg');
			}
		}*/
		/*&.winner { background-image: url('../img/icons/winner.svg');
			&:hover {
				background-image: url('../img/icons/winner-hl.svg');
			}
		}*/
		&.movie {
			background-image: url('../images/play.svg');

			&:hover {
				background-image: url('../images/play-hl.svg');
			}
		}
	}
}

.mobile-top-nav {
	//margin: 20px 0;
	a {
		color: $gray-170;
		font-size: 18px;
		display: block;
		text-decoration: none;
		padding: 10px 0 10px 20px;
		text-transform: uppercase;

		&.link-cyan {
			color: $cyan;
		}

		&:hover {
			color: white;
			background-color: $gray-32;
		}
	}

	border-bottom: solid 1px $gray-170;
	padding-bottom: 20px;

	&:only-child {
		border-bottom: 0;
	}
}


.user-feed {
	list-style-type: none;
	padding: 0;
	margin: 0 20px;
	border-top: solid 1px $gray-170;
	padding-bottom: 20px;
	li {
		border-bottom: solid 1px rgba(68,68,68,1.0);
		padding: 10px 0;

		a {
			color: white;
			text-decoration: none;
			font-size: 14px;
			font-weight: $regular;
			//display: inline-block;
			display: block;
			position: relative;

			@include clearfix;

			p {
				font-size: 14px;
				margin: 0 0 15px 0;
                color: #fff;

				b {
					font-weight: $bold;
				}
			}

			.time {
				position: absolute;
				display: inline-block;
				bottom: 0;
				right: 0;
				color: rgba(68,68,68,1.0);
				height: 12px;
				font-size: 12px;
				text-align: right;
			}

			.provider-logo {
				//height: 20px;
				float: left;
				max-height: 40px;
				max-width: 80px;
			}
		}

		&.show-more {
			text-align: right;
		}

		&:last-child {
			border-bottom: none;
		}
	}
}


/******************************************************
 _
| |    __ _ _ __ __ _  ___
| |   / _` | '__/ _` |/ _ \
| |__| (_| | | | (_| |  __/
|_____\__,_|_|  \__, |\___|
                |___/

******************************************************/
// @include susy-breakpoint($bp-large, $susy) {

// 	.mobile-top-nav {
// 		display: none;
// 	}
// }
