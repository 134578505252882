@import "../../style/constant";
@import "../../style/mixins";

.program-details {
  height: 100%;
  position: relative;

  .justifySpaceBetween {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #616161;
    margin-bottom: 20px;
    .custom-selectBox {
      max-width: calc(100% - 200px);
    }
  }

  %image-darken-overlay {
    // @include linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.35) 65%, rgba(0, 0, 0, 0.55) 100%);
    background-color: transparent;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
  }
  .shade-overlay {
    // position: absolute;
    width: 100%;
    height: 100%;
    &::before {
      // @extend %image-darken-overlay;
      content: '';
      // background-image: radial-gradient(ellipse closest-side, transparent 70%, rgba(black, .8));
      background-position: center 100%;
      background-size: 125%;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .sportsDetails{
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
  }

  .sessons-section {
    margin-bottom: 40px;
    .program-list{
      > div {
        margin: 0 7px 15px;
        width: calc(25% - 14px);
        max-width: calc(25% - 14px);
        min-height: 120px;
        overflow: visible;

        @include tablet {
          width: calc(33.33% - 14px);
        max-width: calc(33.33% - 14px);
        }
        @include allMobiles {
          width: calc(100% - 14px);
        max-width: calc(100% - 14px);
        }



    }
      .item{
        width: 100%;
        max-width:100% ;
      }
    }
   

  }
  .related-info {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    @include allMobiles{
      padding: 0;
    }
    .trailers, .wiki {
      display: flex;
      // grid-template-columns: repeat(2, 1fr);
      // grid-gap: 10px;
      justify-content: start;
      align-items: center;
    }
    .left {
      max-width: 200px;
      max-height: 150px;
      margin-right: 20px;
    }
    .right {
      span {
        display: block;
        &:first-child {
          font-size: 20px;
          font-weight: 900;
        }
      }

    }
  }

  .season-episode {
    display: grid;
    // grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
    > div {
      margin: 0;
      // padding: 5px;
      list-style: none;

      border-radius: 0;
      border: 0;
      margin: 0 7px 15px;
      // max-width: calc(33.33% - 14px);
      min-height: 120px;
      overflow: visible;
      // width: calc(33.33% - 14px);
      .overlay{
        padding: 0;
      }
      .inner-content {
        display: flex;
        justify-content: space-between;
      }
      .image {
        // float: left;
        height: 80px;
        max-width: 100px;
        margin: 0 0 0 0;
        border-radius: 0;
        flex: 0 0 100px;
        img {
          max-height: 100%;
          transform: translateX(0);
        }
      }
      .episode-info {
        // float: left;
        width: calc(100% - 100px);
        color: #222;
        position: relative;
        background: #eeeeee;
        padding: 5px;
        .cardList-title {
          padding: 0;

          h3 {
            color: #222;
            font-size: 13px;
            font-weight: 700;
            margin-top: 18px;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          span {
            position: absolute;
            right: 0;
            top: 0;
            background: #a0295b;
            padding: 2px 4px;
            color: #fff;
            font-size: 10px;
          }
        }
        p {
          color: #222;
          font-size: 12px;

        }
        
      }
    }
  }
  .program-list {
    width: 100%;
    .item {
      width: calc(33.33% - 14px);
      max-width: calc(33.33% - 14px);
      flex: 0 0 33.33%;
    }
  }

}

.mail-to {
  width: 100%;
  clear: both;
}

.action-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 5px 10px;
    cursor: pointer;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
}

.recomendation-list {
  padding-top: 30px;
}

.trailers-list {
  margin: 0;
  padding: 0;
  display: flex;
  li {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    position: relative;
    padding-bottom: 56%;
    height: 200px;
    iframe {
      width: 100%;
      position: absolute;
      height: 100%;
      overflow: hidden;
    }
  }
}

@include respond-above(md) {
  .detail-modal {
    .modal-dialog {
      min-width: 700px;
    }
  }
}

@include respond-below(xs) {
  .program-details {
    h3 {
      font-size: 22px;
    }
  }
  .detail {
    .detail-section .top-section {
      flex-direction: column;
    }
    .detail-section {
      .leftCol, .image {
        width: 100%;
        flex: 0 0 100%;
      }
      .info {
        padding: 30px 0 0 0;
        flex-basis: 100%;
        h1 {
          font-size: 22px;
        }
      }
    }
    .related-videos {
      ul {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .season-episode {
      grid-template-columns: repeat(1, 1fr);
      li {
        .image {
          max-width: 150px;
          flex: 0 0 150px;
        }
        .episode-info {
          width: calc(100% - 150px);
        }
      }
    }
  }

}

/******************************************************
__  __       _     _ _
|  \/  | ___ | |__ (_) | ___
| |\/| |/ _ \| '_ \| | |/ _ \
| |  | | (_) | |_) | | |  __/
|_|  |_|\___/|_.__/|_|_|\___|

******************************************************/
// The title (film, series, program etc.)
.title {
  @include clearfix();
  .icons {
    // list-style-type: none;
    padding: 10px 0 0 0;
    display: inline-block;
    margin-right: 20px;
    @include clearfix();
    .tag {
      display: block;
    }
    a {
      min-height: 50px;
      display: block;
      background-color: #292929;
      //background: url('../img/icons/play-white.svg') no-repeat left center;
      //background-size: 35px 35px;
      /*padding-left: 50px;*/
      padding: 15px 10px 10px 10px;
      border-radius: 2px;

      &:hover {
        text-decoration: none;
        color: white;
      }

      span {
        color: white;
        font-size: 12px;
        margin-bottom: 12px;
      }
    }

  }

  .title-info {
    margin-top: 20px;
    float: none;
    h2.icons{
      display: flex;
      .icon{
        display: inline-block;
        margin: 5px;
        span{
          margin: 0;
          &.tag{
            margin-bottom: 10px;
          }
        }
      }
    }
    h1 {
      font-weight: $semibold;
      font-size: 36px;
      margin: 0;
      @include allMobiles{
        font-size: 24px;
        border: 0;
      }
      @include tablet{
        font-size: 30px;
        border: 0;
      }
    }

    .subtitle {
      font-weight: $regular;
      font-size: 18px;
      color: $gray-139;
      margin: 0 0 1em 0;
    }
    ul {
      margin: 0;
      padding: 0;
      @include clearfix();
    }
    .tags {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
      list-style: none;

      h2, .label {
        display: inline;
        border: 0;
        font-weight: $light;
        font-size: 14px;
        color: $gray-139;
        margin: 0 2px 0 0;
        padding: 0;
      }

      .rating {
        display: inline;
        font-size: 14px;
        font-weight: $bold;
        color: white;
        text-decoration: none;
      }
      span {
        font-weight: normal;
        color: white;
        text-decoration: none;
        font-size: 14px;
        text-transform: capitalize;
        a {
          color: white;
          font-weight: $bold;
        }
      }
      ul {
        display: inline;
        list-style-type: none;
        padding: 0;
        margin: 0;

      }
      .tagType {
        display: inline;
        font-size: 14px;

        a {
          font-weight: $bold;
          color: white;
          text-decoration: none;
        }

        &:after {
          content: ', ';
        }

        &:last-child:after {
          content: '';
        }
      }
    }
    
  }
  .shade-overlay {
    &:before {
      background-size: 150px 150px !important;
    }
  }
  .title-view {
    position: relative;
    // @include span(full);
    float: none;
    @include clearfix();

    > a {
      // Fix for positioning of text-overlay relative to player-image
      display: block;
      position: relative;
    }

    img {
      width: 100%;
    }

    .text-overlay {
      position: absolute;
      color: white;
      bottom: 10px;
      right: 10px;
      text-align: right;

      h3 {
        font-weight: $bold;
        font-size: 18px;
        margin: 0 0 5px 0;
        border:0;
      }

      p {
        font-weight: $light;
        font-size: 14px;
        margin: 0;
      }
    }

    &.show-play {
      .shade-overlay {
        // Overrides the normal shade overlay and adds a double background

        &:before {
          background-image: url('../../images/play-trailer-text-se.svg');
          background-color: rgba(0, 0, 0, 0.5);
          background-size: 100% 100%, 25% 25%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }

    .video-wrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .free-overlay {
      position: absolute;
      left: 20px;
      top: 20px;

      .free-text {
        color: #f7f7f7;
        font-size: 12px;
        //font-weight: $bold;
        text-transform: uppercase;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      }
    }

    .live-overlay {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 12px;
      border: 2px solid white;
      border-radius: 50px;
      padding: 8px 10px;
      box-shadow: inset 1px 1px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;

      > span {
        font-weight: bold;
        color: white;

        &.live-text {
          display: none;
        }
      }

    }

    &.is-finished {
      .live-countdown {
        display: none;
      }
    }

    &.is-live {
      .live-overlay {
        box-shadow: none;
        background: $live-red;
        border: none;
        top: 22px;
        left: 22px;

        > span {
          padding-left: 18px;

          &.live-text {
            display: inline;
          }

          &.time-text {
            display: none;
          }
        }

        &:before {
          content: '';
          width: 10px;
          height: 10px;
          top: 9px;
          background-color: white;
          border-radius: 10px;
          position: absolute;
        }
      }
    }

    .percent-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;

      > .bar {
        background-color: $live-red;
        height: 2px;
      }
    }

    &.is-live .live-countdown {
      display: none;
    }

    .live-countdown {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      color: white;

      > .inner {
        margin-top: -30px;
      }

      .intro {
        text-transform: uppercase;
      }

      .times {
        @include clearfix;
        display: inline-block;

        .time-part {
          float: left;
          /*box-shadow: inset 1px 1px rgba(0,0,0,0.3);*/
          &:last-child {
            margin-right: 0;
          }

          .value {
            font-weight: bold;
            font-size: 40px;
            letter-spacing: 3px;
          }

          .text {
            font-size: 10px;
          }
        }
      }
    }
  }

  .title-article {
    position: relative;
    // @include span(full);
    float: none;
    @include clearfix();

    .section {
      //@include span(full);
      float: none;
      @include clearfix();

      h3 {
        margin-bottom: 15px;
      }

      .image {
        img {
          max-width: 100%;
        }

        margin-bottom: 10px;
      }
    }
  }

  .title-actions {
    @include clearfix;
    margin: 40px 0;
    position: relative;
    .button {
      margin-right: 1em;
    }
  }
}

.seasons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: solid 1px #666666;

  li {
    border-bottom: solid 1px #666666;
    @include clearfix;
    cursor: pointer;

    h2 {
      border: 0;
      margin: 20px 0;
      padding: 0;
      display: inline-block;
    }

    .small-card-block {
      display: none;
    }
  }
}

.related-videos {
  margin-bottom: 40px;
}

// .player-wrapper{
//   height: 300px;
// }

.stream-play {
  .modal-content {
    min-height: 200px;
    .modal-header {
      padding: 7px 0;
      .close{
        right: -15px;
        top: -15px;
        background-color: #000;
        background-image:url(../../images/close.svg);
        background-repeat: no-repeat;
        background-position: 7px 7px;
        background-size: 15px 15px;
      }
    }
    max-width: 800px;
  }
}

/******************************************************
__  __          _ _                      ____                  _ _ 
|  \/  | ___  __| (_)_   _ _ __ ___      / ___| _ __ ___   __ _| | |
| |\/| |/ _ \/ _` | | | | | '_ ` _ \ ____\___ \| '_ ` _ \ / _` | | |
| |  | |  __/ (_| | | |_| | | | | | |_____|__) | | | | | | (_| | | |
|_|  |_|\___|\__,_|_|\__,_|_| |_| |_|    |____/|_| |_| |_|\__,_|_|_|
                                                                  
******************************************************/
@include smallDesktop {
  .title {
    .icons {
      // border-right: solid 1px $gray-68;
      padding-right: 15px;
      margin-right: 10px;

      &:last-child {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
      }
    }
    .shade-overlay {
      &:before {
        background-size: 200px 200px !important;
      }
    }
    .related-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .trailers {
        display: flex;
      }
    }
  }
}

/******************************************************
__  __          _ _
|  \/  | ___  __| (_)_   _ _ __ ___
| |\/| |/ _ \/ _` | | | | | '_ ` _ \
| |  | |  __/ (_| | | |_| | | | | | |
|_|  |_|\___|\__,_|_|\__,_|_| |_| |_|

******************************************************/
@include smallDesktop {

  .title {
    .title-view {
      .live-countdown {
        > .inner {
          margin-top: -60px;
        }

        .intro {
          font-size: em(22px);
        }

        .times {
          .time-part {
            .value {
              font-size: 60px;
              letter-spacing: 3px;
            }

            .text {
              font-size: 14px;
            }
          }
        }
      }

      .free-overlay {
        /*position: absolute;
      left: 20px;
      top: 20px;*/
        .free-text {
          font-size: 16px;
          /*color: #f7f7f7;
          font-weight: $bold;
          text-transform: uppercase;
          text-shadow: inset 1px 1px rgba(0,0,0,0.3);*/
        }
      }
    }

    .title-article {

      .section {
        //@include span(full);
        // @include container;

        h3 {
          margin-bottom: 20px;
        }

        .image {
          // @include span(4);
        }

        .content {
          // @include span(8 last);
        }

        &.right {
          .image {
            // @include span(4 last);
          }

          .content {
            // @include span(8);
            //@include last();
            //@include last();
          }
        }
      }

      /*.section-right {
        @include container;

        .image {
          @include span(4 last);
        }

        .content {
          @include span(8);
          //@include last();
          //@include last();
        }
      }*/
    }
    .shade-overlay {
      &:before {
        background-size: 200px 200px !important;
      }
    }
    
  }
  .program-details .related-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .trailers {
      display: flex;
    }
  }
}

/******************************************************
_                              _
| |    __ _ _ __ __ _  ___  ___| |_
| |   / _` | '__/ _` |/ _ \/ __| __|
| |__| (_| | | | (_| |  __/\__ \ |_
|_____\__,_|_|  \__, |\___||___/\__|
              |___/

******************************************************/
@include largeDesktop {

  .title {

    .title-info {
      // @include span(5);
      margin-top: 0;
      float: left;
      margin-right: 1.69492%;
      margin-top: 0;
      width: 40.67797%;
      h1 {
        margin-bottom: 20px;
      }
    }

    .title-view {
      // @include span(7);
      // @include last();
      float: right;
      width: 57.62712%;
      margin-right: 0;

      img {
        width: 100%;
      }

      .shade-overlay {
        &:before {
          background-size: 200px 200px !important;
        }
      }
      

      .text-overlay {
        bottom: 40px;
        right: 40px;
      }
    }
    .program-details .related-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .trailers {
        display: flex;
      }
    }
  }
}
