@import "../style/constant.scss";
@import "../style/mixins";

.program-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .item {
    color: #fff;
    font-size: 0.8rem;
    padding: 0;
    border-radius: 0;
    position: relative;
    margin: 0 7px 15px 7px;
    min-height: 120px;
    overflow: visible;
    background-position: center center;
    background-size: cover;
    &::before {
      content: " ";
      display: block;
      padding-bottom: 56.986%;
    }
    @include allMobiles() {
      width: 50%;
      max-width: 50%;
    }
    @include mobile() {
      width: 100%;
      max-width: 100%;
      margin: 0 0 10px 0;
    }
    .free-overlay {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 10;

      .free-text {
        color: #f7f7f7;
        font-size: 12px;
        //font-weight: $bold;
        text-transform: uppercase;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      }
    }
    .live-overlay {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 12px;
      border: 2px solid white;
      border-radius: 50px;
      padding: 8px 10px;
      box-shadow: inset 1px 1px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      color: red;
      z-index: 15;
      > span {
        font-weight: bold;
        color: white;

        &.live-text {
          display: none;
        }
      }
    }
    &.is-live {
      .live-overlay {
        box-shadow: none;
        background: $live-red;
        border: none;
        top: 22px;
        left: 22px;

        > span {
          padding-left: 18px;

          &.live-text {
            display: inline;
          }

          &.time-text {
            display: none;
          }
        }

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          top: 9px;
          background-color: white;
          border-radius: 10px;
          position: absolute;
        }
      }
    }
    .percent-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;

      > .bar {
        background-color: $live-red;
        height: 2px;
      }
    }
    .rating {
      color: #9b9b9b;
    }
    img {
      max-width: 100%;
    }
    .overlay {
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.5) 55%,
        rgba(0, 0, 0, 0.7) 100%
      );
      border-bottom: 0;
      padding: 20px;
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      border-radius: 0;
      justify-content: flex-end;
      @include mobile() {
        padding: 5px;
      }
      @include tablet {
        padding: 7px;
      }
      .user-action {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding:5px;
        justify-content: flex-end;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0));       
        span {
          display: inline-block;
        }
        .addTOlist-icon {
          margin-left: 5px;
          height: 25px;
          width: 25px;
        }
      }
      .info-text {
        max-width: 100%;
        line-height: 12px;
        height: auto;
        padding: 10px 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        @include allMobiles{
          position: absolute;
          bottom: 10px;
          height: auto;
          padding: 0;
        }
        @include tablet{
          position: absolute;
          bottom: 10px;
          height: auto;
          padding: 0;
        }
        h2 {
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          margin: 0 0 10px 0;
          @include mobile() {
            margin-bottom: 2px;
          }
        }
        > span, .text-labels span {
          font-size: 13px;
          display: inline-block;
          line-height: 12px;
          text-transform: capitalize;
          padding: 4px;
          // @include mobile() {
          //   font-size: 12px;
          //   padding: 2px;
          // }
        }
        .divider {
          padding: 0 2px;
        }
        .divider:last-child {
          display: none;
        }
        .provider {
          color: #fff;
          padding: 4px;
          font-weight: 700;
        }
        .text-labels{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }

      &.inactive {
        .program {
          color: #9b9b9b;
        }
      }
      &.viewAll {
        text-decoration: underline;
        font-weight: 700;
      }
      &.active {
        .program {
          color: #4fd0ec;
          font-weight: 900;
        }
      }
      .play-icon {
        position: absolute;
        top: 5px;
        right: 40px;
        height: 25px;
        width: 25px;
      }
    }

    ul {
      padding: 10px 0.2rem;
      list-style: none;
      li {
        cursor: pointer;
        font-size: 11px;
      }
      .time {
        color: #b4b4b4;
        display: inline-block;
        vertical-align: middle;
      }
      .status {
        color: #ed7635;
        display: inline-block;
        vertical-align: middle;
      }
      .program {
        display: inline-block;
        max-width: calc(100% - 51px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
}

.netflix-block {
  .provider {
    background: #df1d1f;
  }
}
.dplay-block {
  .provider {
    background: #2175d9;
  }
}
.amazonprimevideo-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: #fff;
          color: #00050d;
        }
      }
    }
  }
}

.svtplay-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: #e13241;
        }
      }
    }
  }
}

.tv4play-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: rgb(224, 0, 28);
        }
      }
    }
  }
}

.hbomax-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: #150426;
        }
      }
    }
  }
}

.viaplay-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: linear-gradient(67deg, #cd207c, #f30000);
        }
      }
    }
  }
}

.appletvplus-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: rgba(255, 255, 255, 0.92);
          color: #00050d;
        }
      }
    }
  }
}

.disneyplus-block {
  .program-list {
    .item {
      .info-text {
        .provider {
          background: hsla(0, 0%, 100%, 0.8);
          color: #000;
        }
      }
    }
  }
}

.grid-add-container {
  margin: -3rem 0 5rem -15px;
}


@include respond-below(md) {
  .program-list {
    margin: 0 -7px;
    .item {
      .overlay {
        .play-icon {
          position: absolute;
          bottom: 1px;
          right: 42px;
          height: 28px;
          width: 28px;
          @include allMobiles{
            height: 20px;
            width: 20px;
            right: 30px;
          }
          @include tablet{
            height: 20px;
            width: 20px;
            right: 30px;
          }
        }
        .user-action {
          bottom: 5px;
          // padding: 0;
          height: 30px;
          // @include allMobiles{
          //   height: 20px;
          //   top:0;
          // }
          // @include tablet{
          //   height: 20px;
          //   top:0;
          // }
        }
      }
    }
  }
}
@include respond-below(sm) {
  .program-list {
    .item {
      width: 100%;
      max-width: 100%;
    }
  }
  .navbar {
    justify-content: flex-end;
  }
}

@include mobile {
  .grid-add-container {
    min-height: 320px;
    margin-left: 0;
  }
}

@include respond-below(xs) {
  .program-list {
    display: block;
    width: 100%;
    .item {
      width: 100%;
      max-width: 100%;
      .top-logo {
        top: -3rem;
      }
    }
  }
}
