@import "../../style/constant";
@import "../../style/mixins";

.detail {
  height: 100%;
  position: relative;
  padding-top: 300px;
  max-width: 1600px;
  .heroImage {
    position: absolute;
    height: 400px;
    width: 100%;
    overflow: hidden;
    top: 0;
    text-align: center;
    img {
      min-width: 70%;
    }
    .overlay {
      position: absolute;
      z-index: 1;
      //   background-image: linear-gradient(
      //     180deg,
      //     rgba(0, 0, 0, 0.2) 0%,
      //     rgba(0, 0, 0, 0.35) 55%,
      //     rgba(0, 0, 0, 0.55) 100%
      //   );
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      &::before {
        content: "";
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+46,000000+88&0.65+0,1+0,1+61,0+100 */
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 61%, rgba(0, 0, 0, 0.31) 88%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 61%, rgba(0, 0, 0, 0.31) 88%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 61%, rgba(0, 0, 0, 0.31) 88%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1); /* IE6-9 */

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 25%;
      }
      &::after {
        content: "";
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+6,000000+46&0+0,1+33,1+47 */
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 6%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 6%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 6%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1); /* IE6-9 */

        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 22%;
      }
    }
  }
  .detail-section {
    padding: 20px;
    border-radius: 10px;
    max-width: 900px;
    margin: 0 auto;
    background: #131313;
    position: relative;
    z-index: 9;

    .top-section {
      display: flex;
    }
    .leftCol {
      width: 250px;
      flex: 0 0 250px;
      .user-action {
        position: static;
        background: #1c252f;
        padding: 30px 10px 10px 10px;
        border-radius: 10px;
        width: 100%;
        position: relative;
        height: auto;
        a {
          height: 30px;
          width: 30px;
        }
        .title {
          position: absolute;
          color: #fff;
          top: 5px;
          font-size: 14px;
        }
      }
    }
    .image {
      width: 250px;
      height: 350px;
      overflow: hidden;
      border-radius: 10px;
      flex: 0 0 250px;
      margin-bottom: 10px;
      img {
        height: 100%;
        // transform: translateX(-30%);
      }
    }
    .info {
      flex: 0 0 calc(100% - 250px);
      padding-left: 20px;
      .top {
        display: flex;
        flex: 0 0 100%;
      }
      h1 {
        color: #fff;
        font-size: 30px;
        span {
          color: #c1c1c1;
          font-size: 16px;
          display: inline-block;
        }
      }
      p {
        color: #fff;
      }
      .tag {
        background: #a0295b;
        display: inline-block;
        font-size: 10px;
        padding: 2px 10px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 15px;
      }
      h2 {
        color: #fff;
        font-size: 24px;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        max-width: 200px;
        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          span {
            color: #fff;
          }
          .label {
            font-weight: bold;
            color: #fff;
          }
        }
      }
    }
    .program-list {
      width: 100%;
      .item {
        width: calc(33.33% - 14px);
        max-width: calc(33.33% - 14px);
        flex: 0 0 33.33%;
      }
    }
  }
}

.mail-to {
  width: 100%;
  clear: both;
}

.action-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 5px 10px;
    cursor: pointer;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
}

.cast-details{
  padding-top: 60px;
  h1{
    font-size: 26px !important;
    font-weight: bold;
  }
}
.cast-list{
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction:row;
  flex-wrap:wrap;
  display: flex;
  li{
    display: flex;
    flex-direction: column;
    margin: 0 10px 20px 10px;
    max-width: 90px;
    text-align: center;
  }
  .image{
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
    img{
      max-height: 70px;
      border-radius: 50%;
    }
  }
}

.related-material {
  margin-bottom: 30px;
}

@include respond-above(md) {
  .detail-modal {
    .modal-dialog {
      min-width: 700px;
    }
  }
}

@include respond-below(xs) {
  .cast-details{
    padding-top: 40px;
  }
  .cast-list{
    li{
      max-width: 70px;
      margin: 0 5px 10px 5px;
      span.name{
        font-size: 11px;
      }
    } 
    .image{
      height: 50px;
      width: 50px;
      margin-bottom: 10px;
      img{
        max-height: 50px;
        border-radius: 50%;
      }
    }
  }
}


